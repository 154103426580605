import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as translators from '../../util/tranlators';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    
  },
  root: {
    width: '1000px',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
}));


// todo continue here: update columns
const ContainerDialog = (props) => {
  
  const classes = useStyles();

  const renderContainerNumber = (number) => {
    if(number) {
      return (
        <span>{number}.&nbsp;</span>
      );
    }
  }

  const renderContent = () => {
    if(!props.fetching) {
      if(props.containers.length === 0) {
        return (
          <DialogContentText id="alert-dialog-slide-description">
            Derzeit keine Container vorhanden
          </DialogContentText>
        );
      }
      return(
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Containernummer</TableCell>
                <TableCell align="left">BOX / HC</TableCell>
                <TableCell align="left">Siegelnummer</TableCell>
                <TableCell align="left">Status</TableCell>  
                <TableCell align="left">Aktuelle Position</TableCell>  
              </TableRow>
            </TableHead>
            <TableBody>
              {props.containers.map(container => (
                <TableRow key={container.containerId}>
                  <TableCell component="th" scope="row">
                    {container.containerId}
                  </TableCell>
                  <TableCell align="left">
                    {translators.getBoxLabel(container.box)}
                  </TableCell>
                  <TableCell align="left">
                    {translators.getSealIdLabel(container.sealId)}
                  </TableCell>
                  <TableCell align="left">
                    {renderContainerNumber(container.containerNumber)}
                    {translators.getContainerStateLabel(container.fixedState)}
                  </TableCell>
                  <TableCell align="left">
                    {translators.getContainerStateLabel(container.containerState)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      );
    }
    else {
      return (
        <Grid container justify='center'>
          <CircularProgress />
        </Grid>
      );
    }
  }

  
  return (
    <Dialog
    className={classes.dialogRoot}
    open={props.open}
    TransitionComponent={Transition}
    keepMounted
    onClose={props.handleClose}
    maxWidth='xl'
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
      <DialogTitle id="alert-dialog-slide-title">
        {`Container für Freistellnummer ${props.lotId}`}
      </DialogTitle>
      <DialogContent>

        {renderContent()}
        
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
  
}

const mapStateToProps = (state) => {
  return {
    containers: state.export.singleLot.containers,
    fetching: state.export.singleLot.fetching
  }
};

export default connect(mapStateToProps, null)(ContainerDialog);
