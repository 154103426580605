import React, { useEffect } from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  TextField, 
  DialogActions, 
  Button, 
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ClearIcon from '@material-ui/icons/Clear';

import * as actions from '../../actions/index';
import * as mailService from '../../services/MailService';


const useStyles = makeStyles(theme => ({
  textField: {
    width: '400px',
  },
  smallTextField: {
    width: '200px'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem'
  },
  errorButton: {
    color: 'red'
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.red
    }
  },
  dialogContent: {
    '-webkit-box-shadow': 'inset 0 -6px 10px -6px black',
    '-moz-box-shadow': 'inset 0 -6px 10px -6px black',
    boxShadow: 'inset 0 -6px 10px -6px black',
  },
  saveButton: {
    color: theme.palette.secondary.main
  }

}));

const validate = values => {
  const errors = {}
  const requiredFields = [
    'messageContent',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `Bitte ausfüllen`
    }
  }); 
  return errors;
}

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
  }) => {  
  const errorText = (error && touched) ? error : '';
  return (
    <TextField
      error={error && touched}
      label={label}
      helperText={errorText}
      {...input}
      {...custom}  
    />
  );
}


const SendMessageDialog = (props) => {

  const classes = useStyles();
  
  useEffect(() => {
    props.initialize({ 
      userName: props.userName
    });
  },[props.open]);

  

  const submitForm = async (formProps) => {
    props.reset();
    try {
      await mailService.sendMailToAdmin(formProps);
      props.showNotification('Nachricht wurde gesendet', false);
    } catch (error) {
      props.showNotification('Nachricht konnte nicht gesendet werden', true);
    }
    props.close();
  }

  
  return (
    <form>
      <Dialog 
        open={props.open} 
        onClose={props.close} 
        aria-labelledby="sendMessage"
        fullScreen
      >
        <ClearIcon onClick={props.close} className={classes.closeIcon} />
        <DialogTitle id="sendMesage">Nachricht senden</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Felder mit * müssen ausgefüllt werden:
          </DialogContentText>

          <Grid container justify='center'>

            <Grid item xs={12}>
              <Grid container justify='center'>
                <Field
                  name="userName"
                  component={renderTextField}
                  label="Username"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>


            <Grid item xs={12}>
              <Grid container justify='center'>
                <Field
                  name="messageContent"
                  component={renderTextField}
                  multiline
                  rows="4"
                  rowsMax="6"
                  label="Nachricht"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            

          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions} >
          <Button onClick={props.close} color="inherit">
            Abbrechen
          </Button>
          <Button 
            onClick={props.handleSubmit(submitForm)} 
            className={classes.saveButton}
            color='inherit' 
            disabled={props.pristine || props.submitting || props.invalid}
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default compose(
  reduxForm({
    form: 'sendMessageDialog',
    validate
  }),
  connect(null, actions)
)(SendMessageDialog);
