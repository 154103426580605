// prod
export const API_URL = 'https://tsrva3tfj5.execute-api.eu-central-1.amazonaws.com/prod';
export const WS_URL = 'wss://gc772embh2.execute-api.eu-central-1.amazonaws.com/prod';

// dev
/*
export const API_URL = 'https://h7ytsj9uej.execute-api.eu-central-1.amazonaws.com/dev';
export const WS_URL = 'wss://2zow8lvmnb.execute-api.eu-central-1.amazonaws.com/dev';
*/

export const GET_ACTIVE_ACTION = 'getAllActive';
export const ADD_CONTAINER = 'addContainer';
export const UPDATE_CONTAINER = 'updateContainer';
export const DELETE_CONTAINER = 'deleteContainer';
export const UPDATE_BOARD_ACTION = 'updateBoard';

export const STATE_PARKING = 'parking';
export const STATE_BODY1 = 'body1';
export const STATE_BODY2 = 'body2';
export const STATE_BODY3 = 'body3';
export const STATE_RAHMEN1 = 'rahmen1';
export const STATE_RAHMEN2 = 'rahmen2';
export const STATE_KIT1 = 'kit1';
export const STATE_KIT2ADR = 'kit2adr';
export const STATE_MOVING = 'moving';
export const STATE_TERMINAL = 'terminal';
