import * as constants from '../constants/auth/constants';

import * as AuthService from '../services/AuthService';
import * as notificationActions from './notificationActions';


export const checkIfUserIsAuthenticated = () => async dispatch => {

  try {
    const session = await AuthService.getUserSession();
    const user = AuthService.getAuthenticatedUser().username;
    const role = await AuthService.getUserRole();

    dispatch({
      type: constants.AUTH_LOGIN_SUCCESS,
      payload: {
        session,
        user,
        role
      },
    });
  }
  catch(err) {
    dispatch({
      type: constants.AUTH_LOGIN_ERROR,
      payload: ''
    });
  }

}


export const login = (formProps) => async dispatch => {
  
  dispatch({ 
    type: constants.AUTH_LOGIN_REQUEST
  });
  
  try {

    const session = await AuthService.signIn(formProps.username, formProps.password);
    console.log("sessionType: ", session.type);
    
    if(session.type === constants.AUTH_SIGNIN_RESPONSE_SUCCESS) {
      const user = AuthService.getAuthenticatedUser().username;
      try {
        const role = await AuthService.getUserRole();
        dispatch(notificationActions.showNotification(`Hallo ${user}`, false));
        dispatch({
          type: constants.AUTH_LOGIN_SUCCESS,
          payload: {
            session,
            user,
            role
          }
        });
      }
      catch(err){ 
        console.log("error ", err);
      }
    }
    else if(session.type === constants.AUTH_SIGNIN_RESPONSE_NEWPW) {
      console.log("authActions: newPw");
      dispatch(notificationActions.showNotification('Login erfolgreich: neues Passwort erforderlich', false));
      dispatch({
        type: constants.AUTH_LOGIN_SUCCESS_NEWPW
      });
    }
    
    
    
  }
  catch (err) {
    console.log("Dispatching error: ", err);
    dispatch({
      type: constants.AUTH_LOGIN_ERROR,
      payload: 'Username oder Passwort falsch'
    });
    dispatch(notificationActions.showNotification('Login fehlgeschlagen', true));
  }
};


export const logout = () => async dispatch => {
  AuthService.logout();
  dispatch({
    type: constants.AUTH_LOGIN_ERROR,
    payload: ''
  });
}


export const setNewPassword = (formProps) => async dispatch => {

  dispatch({
    type: constants.AUTH_LOGIN_NEWPW_REQUEST
  });

  try {
    const session = await AuthService.setNewPassword(formProps.password);
    const user = AuthService.getAuthenticatedUser().username;
    dispatch(notificationActions.showNotification(`Hallo ${user}`, false));
    dispatch({
      type: constants.AUTH_LOGIN_SUCCESS,
      payload: {
        session,
        user
      }
    });
  }
  catch(err) {
    console.log("Actionerror:", err);
    dispatch({
      type: constants.AUTH_LOGIN_NEWPW_ERROR,
      payload: err.message
    });
  }
  



}