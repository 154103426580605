import * as lotConstants from '../constants/lots/constants';
import * as lotService from '../services/LotService';
import * as notificationActions from './notificationActions';

export const getAllLots = () => async dispatch => {

  dispatch({
    type: lotConstants.GET_ALL_LOTS_REQUEST
  });

  try {
    let result = await lotService.getAllLots();
    
    dispatch({
      type: lotConstants.GET_ALL_LOTS_SUCCESS,
      payload: sortByState(result.data.data)
    });
  }
  catch (err) {
    console.log(err);
    dispatch({
      type: lotConstants.GET_ALL_LOTS_ERROR,
      payload: err.message
    });
  }

}

export const addNewLot = (lot) => async dispatch => {
  dispatch({
    type: lotConstants.ADD_LOT_REQUEST
  });

  try {
    await lotService.addLot(lot);
    let res = await lotService.getAllLots();
    dispatch(notificationActions.showNotification(`Lot ${lot.lotId} wurde hinzugefügt`, false));
    dispatch({
      type: lotConstants.ADD_LOT_SUCCESS,
      payload: sortByState(res.data.data)
    });

  }
  catch(err) {
    dispatch(notificationActions.showNotification(`Lot ${lot.lotId} konnte nicht hinzugefügt werden`, true));
    dispatch({
      type: lotConstants.ADD_LOT_ERROR,
      payload: err.message
    });
  }
}

export const editLot = (lot) => async dispatch => {
  dispatch({
    type: lotConstants.ADD_LOT_REQUEST
  });

  try {
    await lotService.setLot(lot);
    let res = await lotService.getAllLots();
    dispatch(notificationActions.showNotification(`Lot ${lot.lotId} wurde geändert`, false));
    dispatch({
      type: lotConstants.ADD_LOT_SUCCESS,
      payload: sortByState(res.data.data)
    });

  }
  catch(err) {
    dispatch(notificationActions.showNotification(`Lot ${lot.lotId} konnte nicht geändert werden`, true));
    dispatch({
      type: lotConstants.ADD_LOT_ERROR,
      payload: err.message
    });
  }

}


const sortByState = (lots) => {
  let sortedByState = lots.sort((a,b) => {
    var nameA=a.lotState.toLowerCase(), nameB=b.lotState.toLowerCase();
    if (nameA < nameB) //sort string ascending
      return -1;
    if (nameA > nameB)
      return 1;
    return 0; //default return value (no sorting)
  });

  return sortedByState;
}

