import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase } from '@material-ui/core';
import { connect } from 'react-redux';

import * as actions from '../../../actions/index';


const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      //backgroundColor: 'white',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

const SearchBar = (props) => {

  useEffect(() => {
    props.getLotsByQuery('');
  }, [])

  const classes = useStyles();

  const handleInputChange = (event) => {
    fetchItems(event.target.value);
  }

  var delayTimer;
  const fetchItems = (text) => {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      props.getLotsByQuery(text);
    }, 300);
  }

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Suche..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onChange={handleInputChange}
      />
    </div>
  );
}

export default connect(null, actions)(SearchBar);