import { useState, useEffect } from 'react';
import * as apiConstants from '../../constants/api/constants';


var socket = undefined;

const useWebSocket = () => {
  
  // contains all active data coming in from the websocket
  const [activeData, setActiveData] = useState();
  const [newCardData, setNewCardData] = useState();
  const [updateCardData, setUpdateCardData] = useState();
  const [removeCardData, setRemoveCardData] = useState();
  const [reconnect, setReconnect] = useState(0);
  const [connectionOpen, setConnectionOpen] = useState(false);
  
  useEffect(() => {
  
    socket = new WebSocket(apiConstants.WS_URL);

    socket.addEventListener('open', (event) => {
      
      setConnectionOpen(true);
      sendMessage({action: apiConstants.GET_ACTIVE_ACTION});
    });

    socket.addEventListener('message', (event) => {
      
      let incomingMessage = JSON.parse(event.data);

      switch(incomingMessage.action) {

        case apiConstants.GET_ACTIVE_ACTION:
          setActiveData(incomingMessage.data);
          break;
        
        case apiConstants.UPDATE_CONTAINER:
          setUpdateCardData(incomingMessage.data);
          break;
        
        case apiConstants.ADD_CONTAINER:
          setNewCardData(incomingMessage.data);
          break;
        
        case apiConstants.DELETE_CONTAINER:
          setRemoveCardData(incomingMessage.data);
          break;
        
        default:
          break;
      
      }
    });

    socket.addEventListener('close', (event) => {
      
      setConnectionOpen(false);
      setTimeout(() => {
        
        setReconnect(reconnect+1);
      }, 1000);
      
    });

    socket.addEventListener('error', (event) => {
      console.error("ws error: ", event);
      socket.close();
    });

    

    return () => {
      
      socket.removeEventListener('open', setActiveData);
      socket.removeEventListener('message', setActiveData);
      socket.removeEventListener('error', console.log);
      socket.removeEventListener('close', console.log);
      socket.close();
    }
  }, [reconnect]);


  const sendMessage = (msgToSend) => { 
    
    socket.send(JSON.stringify(msgToSend)) 
  }


  return [
    activeData, 
    newCardData, 
    updateCardData, 
    removeCardData, 
    connectionOpen,
    sendMessage
  ];

}

export default useWebSocket;