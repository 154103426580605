import * as userConstants from '../constants/users/constants';
import * as userService from '../services/UserService';
import * as notificationActions from './notificationActions';

export const getAllUsers = () => async dispatch => {

  dispatch({
    type: userConstants.GET_ALL_USERS_REQUEST
  });

  try {
    let response = await userService.getAllUsers();
    let users = response.data.data;
    dispatch({
      type: userConstants.GET_ALL_USERS_SUCCESS,
      payload: users
    });
  }
  catch(err) {
    dispatch({
      type: userConstants.GET_ALL_USERS_ERROR,
      payload: err.message
    });
  }
}

export const getSingleUser = (userName) => async dispatch => {
  dispatch({
    type: userConstants.GET_USER_REQUEST
  });

  try {
    let response = await userService.getSingleUser(userName);
    dispatch({
      type: userConstants.GET_USER_SUCCESS,
      payload: {
        user: response.data.data.Username,
        userAttributes: response.data.data.UserAttributes
      }
    });
  }
  catch(err) {
    dispatch({
      type: userConstants.GET_USER_ERROR,
      payload: err.message
    });
  }
}

export const deleteSingleUser = (userName, callback) => async dispatch => {
  dispatch({
    type: userConstants.UPDATE_USER_REQUEST
  });

  try {
    await userService.deleteSingleUser(userName);
    callback();
    dispatch(notificationActions.showNotification(`${userName} gelöscht`, false));
    dispatch({
      type: userConstants.UPDATE_USER_SUCCESS,
    });
  }
  catch(err) {
    dispatch(notificationActions.showNotification(`${userName} konnte nicht gelöscht werden`, true));
    dispatch({
      type: userConstants.UPDATE_USER_ERROR,
      payload: err.message
    });
  }
}

export const resetPwForSingleUser = (userName, userAttributes, callback) => async dispatch => {
  dispatch({
    type: userConstants.UPDATE_USER_REQUEST
  });

  try {
    await userService.resetPwForSingleUser(userName, userAttributes);
    callback();
    dispatch(notificationActions.showNotification(`Passwort von ${userName} zurückgesetzt`, false));
    dispatch({
      type: userConstants.UPDATE_USER_SUCCESS,
    });
  }
  catch(err) {
    console.log(err);
    dispatch(notificationActions.showNotification(`Passwort von ${userName} konnte nicht zurückgesetzt werden`, true));
    dispatch({
      type: userConstants.UPDATE_USER_ERROR,
      payload: err.message
    });
  }
}

export const addNewUser = (formProps, callback) => async dispatch => {
  dispatch({
    type: userConstants.ADD_USER_REQUEST
  });
  try {
    
    let user = await userService.addNewUser(formProps.username, formProps);
    console.log('added user: ', user);
    dispatch(notificationActions.showNotification(`User ${formProps.username} wurde hinzugefügt`, false));
    dispatch({
      type: userConstants.ADD_USER_SUCCESS
    });
    callback();
  }
  catch(err) {
    console.log('error: ', err);
    dispatch({
      type: userConstants.ADD_USER_ERROR,
      payload: err.message
    });
    dispatch(notificationActions.showNotification(`User ${formProps.username} konnte nicht hinzugefügt werden`, true));
  }
}