import * as constants from '../constants/users/constants';

const INITIAL_STATE = {
  users: [],
  fetching: false,
  error: ''
};

export default function(state=INITIAL_STATE, action) {

  switch(action.type) {

    case constants.GET_ALL_USERS_REQUEST: 
      return {
        ...state,
        fetching: true,
        error: ''
      };
    
    case constants.GET_ALL_USERS_SUCCESS: 
      return {
        users: action.payload,
        fetching: false,
        error: ''
      };

    case constants.GET_ALL_USERS_ERROR: 
      return {
        ...state,
        fetching: false,
        error: action.payload
      };
    
    case constants.ADD_USER_REQUEST: 
      return {
        ...state,
        fetching: true
      }

    case constants.ADD_USER_SUCCESS: 
      return {
        ...state,
        fetching: false
      } 
    
    case constants.ADD_USER_ERROR: 
      return {
        ...state,
        fetching: false,
        error: action.payload
      } 

    default:
        return state;
  }
}