// prod
export const USER_POOL_ID = 'eu-central-1_8Wa3FIbsQ';
export const CLIENT_ID = '51mgg8dv1urrj776a348gb6r2r';
export const ADMIN_ROLE = 'magnaContainer-prod-userPoolAdminGroup';

// dev
/*
export const USER_POOL_ID = 'eu-central-1_BnA30Um06';
export const CLIENT_ID = '17npip3jdhnt0jk9thiarop96t';
export const ADMIN_ROLE = 'magnaContainer-dev-userPoolAdminGroup';
*/


