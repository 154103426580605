import React from 'react'
import {useDropzone} from 'react-dropzone';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,  
  DialogActions, 
  Button, 
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import FileIcon from '@material-ui/icons/InsertDriveFile';

import * as actions from '../../actions/index';
import * as fileService from '../../services/FileService';


const useStyles = makeStyles(theme => ({
  dropZone: {
    width: '500px',
    height: '200px',
  },
  dropInput: {
    border: '2px dashed grey',
    height: '100%',
  },
  dropText: {
    fontFamily: 'Roboto',
    textAlign: 'center'
  },
  fileDisplay: {
    fontFamily: 'Roboto',
    marginTop: '20px'
  }
}));


const UploadPlannigDialog = (props) => {

  const classes = useStyles();

  const onFileDropped = (file) => {
    //console.log('file dropped: ', file);
  }

  const [isLoading, setIsLoading] = React.useState(false);
  const {acceptedFiles, getRootProps, getInputProps, inputRef} = useDropzone({
    accept: '.xlsx',
    onDrop: onFileDropped
  });

  const removeAll = () => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    inputRef.current.value = ''
  }

  const uploadFile = async() => {
    setIsLoading(true);

    try {
      let urlResp = await fileService.requestUploadUrl();  
      let uploadUrl = urlResp.data.uploadURL;
      await fileService.uploadFile(uploadUrl, acceptedFiles[0]);
      removeAll();
      setIsLoading(false);
      props.showNotification('Datei wurde hochgeladen', false);
      props.close();
    } 
    catch (error) {
      props.showNotification('Datei konnte nicht hochgeladen werden', false);
      setIsLoading(false);
      console.log(error);
    }

  }
  
  
  
  return (
    <form>
      <Dialog 
        open={props.open} 
        onClose={props.close} 
        aria-labelledby="form-dialog-upload"
        fullScreen
      >
        <DialogTitle id="form-dialog-title">Vorplanung hochladen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Felder mit * müssen ausgefüllt werden:
          </DialogContentText>
          <Grid container justify='center'>

            <Grid item xs={12}>
              <Grid container justify='center'>
                <section className={classes.dropZone}>
                  <div 
                    {...getRootProps({className: 'dropzone'})}
                    className={classes.dropInput}
                  >
                    <input {...getInputProps()} />
                    <p className={classes.dropText}>.xlsx Datei herziehen oder klicken</p>
                  </div>
                </section>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justify='center' className={classes.fileDisplay}>
                
                {
                  acceptedFiles[0] && (
                    <div>
                      <FileIcon />&nbsp;{acceptedFiles[0].name}
                    </div>
                  )
                }
              </Grid>
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="inherit">
            Abbrechen
          </Button>
          <Button 
            color='primary' 
            disabled={acceptedFiles.length !== 1}
            onClick={uploadFile}
          >  
            {(isLoading) ? <CircularProgress size={24} /> : "Hochladen"}
            
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default connect(null, actions)(UploadPlannigDialog);
