import { useEffect } from 'react';

import * as apiConstants from '../../constants/api/constants';

var socket = undefined;

const useWriteWebSocket = () => {
  
  useEffect(() => {
    socket = new WebSocket(apiConstants.WS_URL);
    socket.addEventListener('open', (event) => {
      //console.log("ws open: ", event);
    });

    socket.addEventListener('close', (event) => {
      //console.log("ws disconnect: ", event);
    });

    socket.addEventListener('error', (event) => {
      //console.log("ws error: ", event);
    });

    return () => {
      socket.removeEventListener('open', console.log);
      socket.removeEventListener('error', console.log);
      socket.removeEventListener('close', console.log);
      socket.close();
    }
  }, []);


  const sendMessage = (msgToSend) => { 
    //console.log('msgToSend: ',msgToSend);
    socket.send(JSON.stringify(msgToSend)) 
  }

  return [
    sendMessage
  ];

}

export default useWriteWebSocket;