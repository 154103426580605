import React, { useEffect } from 'react';
import { connect } from 'react-redux';


export default ChildComponent => {
  const ComposedComponent = (props) => {

    const { auth, history } = props;

    useEffect(() => {
      shouldNavigateAway();
    }, [auth]);

    const shouldNavigateAway = () => {
      if (!auth.authenticated) {
        history.push('/login');
      } 
      else if(auth.role !== 'admin') {
        history.push('/board');
      }
    };
    
    return (
      <ChildComponent {...props} />
    );
    
  }

  const mapStateToProps = (state) => {
    return { auth: state.auth };
  }

  return connect(mapStateToProps, null)(ComposedComponent);
};