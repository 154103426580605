import axios from 'axios';
import { rows as lotRows } from '../components/admin/Export/TableHead';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import FileSaver from 'file-saver';
import * as jsPDF from 'jspdf';

import { API_URL } from '../constants/api/constants';
import * as authService from './AuthService';
import * as lotService from './LotService';
import * as translators from '../components/util/tranlators';
import { logo } from '../static/index';


export const getLotsByQuery = async(query) => {
  let url = `${API_URL}/lot/query/${query}`;
  try {
    let headers = await authService.getAuthHeaders();
    return axios.get(url, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
}

export const exportToPdf = async(lots, callback) => {
  
  const now = new Date();
  const nowLabel = now.toLocaleString();
  const filename = `lot_export_${now.toLocaleDateString()}_${now.toLocaleTimeString()}.pdf`;
  var doc = new jsPDF();
  var firstFlag = true;

  const printReportDetails = () => {

    if(!firstFlag) {
      // add new Page
      doc.addPage();
    }
    firstFlag = false;
    
    // logo
    doc.addImage(logo, 'png', 165, 5, 40, 12);

    // title
    doc.setFontSize(20);
    doc.text('Lot Report', 10, 30);

    // date
    doc.setFontSize(10);
    doc.text(nowLabel, 10, 35);

    // container label
    doc.setFontSize(14);
    doc.text('Container:', 15, 80);
  }

  const printLotDetails = (lot) => {

    // lot details
    // label
    doc.setFontSize(14);
    doc.text('Lot:', 15, 45);

    // lotNumber
    doc.setFontSize(10);
    doc.text('Lotnummer:', 20, 55);
    doc.text(lot.lotNumber, 60, 55);

    // lotId
    doc.setFontSize(10);
    doc.text('Freistellnummer:', 20, 60);
    doc.text(lot.lotId, 60, 60);

    // containers
    doc.setFontSize(10);
    doc.text('Anzahl Container:', 20, 65);
    doc.text(lot.containers, 60, 65);
  }

  const printContainerDetails = (container, pdfHeight) => {
  
    // containerId
    doc.setFontSize(10);
    doc.text('ContainerId:', 20, pdfHeight+10);
    doc.text(container.containerId, 60, pdfHeight+10);

    // box
    doc.setFontSize(10);
    doc.text('BOX / HC:', 20, pdfHeight+15);
    doc.text(translators.getBoxLabel(container.box), 60, pdfHeight+15);

    // sealId
    doc.setFontSize(10);
    doc.text('Siegelnummer:', 20, pdfHeight+20);
    doc.text(translators.getSealIdLabel(container.sealId), 60, pdfHeight+20);

    // containerNumber
    doc.setFontSize(10);
    doc.text('Nummer:', 20, pdfHeight+25);
    doc.text(String(container.containerNumber), 60, pdfHeight+25);

    // containerState
    doc.setFontSize(10);
    doc.text('Status:', 20, pdfHeight+30);
    doc.text(translators.getContainerStateLabel(container.fixedState), 60, pdfHeight+30);
  }
  
  const lotsWithContainers = await fetchContainersForAllLots(lots);

  lotsWithContainers.forEach(lot => {

    printReportDetails();
    printLotDetails(lot.lotData);
    let pdfHeight = 80;
    lot.containerData.forEach(container => {
      printContainerDetails(container, pdfHeight);
      pdfHeight += 29;
    });
  });

  doc.save(filename);

  callback();
}

export const exportToXls = async(lots, callback) => { 
  let now = new Date();
  var filename = `lot_export_${now.toLocaleDateString()}_${now.toLocaleTimeString()}.xlsx`;
  var workbook = new Excel.Workbook();
  workbook.creator = 'Fahrner Overland GmbH';
  workbook.lastModifiedBy = 'Fahrner Overland GmbH';
  workbook.created = new Date();
  workbook.modified = new Date();
 
  var lotWs = workbook.addWorksheet('Lots');
  var containerWs = workbook.addWorksheet('Container');
  
  const containerColumns = [
    {
      header: 'Freistellnummer',
      key: 'lotId',
      width: 20
    },
    {
      header: 'Lotnummer',
      key: 'lotNumber',
      width: 20
    },
    {
      header: 'Containernummer',
      key: 'containerId',
      width: 20
    },
    {
      header: 'BOX / HC',
      key: 'box',
      width: 20
    },
    {
      header: 'Siegelnummer',
      key: 'sealId',
      width: 20
    },
    {
      header: 'Nummer',
      key: 'containerNumber',
      width: 20
    },
    {
      header: 'Containerstatus',
      key: 'fixedState',
      width: 20
    },
  ];
  
  const lotColumns = lotRows.map(row => {
    return {
      header: row.label,
      key: row.id,
      width: 20
    }
  });
  
  const headerCellStyle = { 
    alignment: {
      vertical: 'middle', 
      horizontal: 'left' 
    },
    font: {
      size: 13,
      bold: true
    },
    border: {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    },
    fill: {
      type: 'pattern',
      pattern:'solid',
      bgColor:{argb:'FFB0C4DE'},
      fgColor:{argb:'FFB0C4DE'}
    }
  }
  

  lotWs.columns = lotColumns;
  containerWs.columns = containerColumns;

  
  let cells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1'];
  cells.forEach(cell => {
    lotWs.getCell(cell).style = headerCellStyle;
    containerWs.getCell(cell).style = headerCellStyle;
  });

  lots.forEach(lot => {
    lotWs.addRow({
      lotId: lot.lotId, 
      lotNumber: lot.lotNumber, 
      containers: lot.containers,
      created: lot.created,
      lotState: lot.lotState
    });
  });
  
  
  // fetch containers for all lots
  try {
    
    let containers = await fetchContainersForAllLots(lots);

    containers.forEach(lot => {
      lot.containerData.forEach(container => {
        containerWs.addRow({
          lotId: lot.lotData.lotId,
          lotNumber: lot.lotData.lotNumber,
          box: translators.getBoxLabel(container.box),
          containerId: container.containerId,
          sealId: translators.getSealIdLabel(container.sealId),
          containerNumber: container.containerNumber,
          fixedState: translators.getContainerStateLabel(container.fixedState)
        });
      })
    });

    
  }
  catch(err) {
    console.log(err);
  }

  
  workbook.xlsx.writeBuffer()
    .then(function(data) {
      var blob = new Blob(
        [data], 
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        }
      );
      FileSaver.saveAs(blob, filename);
    });

  callback();

}

const fetchContainersForAllLots = async (lots) => {
  let fetchedContainers = lots.map( async(lot) => {
    let res = await lotService.getLotById(lot.lotId);
    return {
      containerData: res.data.data,
      lotData: lot
    };
  });
  
  let finalArray = await Promise.all(fetchedContainers);
  return finalArray;
}