import React, { useEffect } from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  TextField, 
  DialogActions, 
  Button, 
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as actions from '../../actions/index';


const useStyles = makeStyles(theme => ({
  textField: {
    width: '300px'
  },
  smallTextField: {
    width: '200px'
  },
  selectField: {
    marginTop: '18px'
  },
}));

const validate = values => {
  const errors = {}
  const requiredFields = [
    'lotId',
    'containers',
    'colorCode'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `Bitte ausfüllen`
    }
  })

  return errors;
}


const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
  }) => {  
  const errorText = (error && touched) ? error : '';
  return (
    <TextField
      error={error && touched}
      label={label}
      helperText={errorText}
      {...input}
      {...custom}  
    />
  );
}

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  const errorText = (error && touched) ? error : '';
  return(
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-lot-simple">Containeranzahl*</InputLabel>
      <Select
        {...input}
        onChange={(event, index, value) => { input.onChange(event.target.value) }}
        children={children}
        input={
          <OutlinedInput
            id="outlined-lot-simple" 
            labelWidth={120}
          />
        }
        {...custom}
      />
      <FormHelperText style={{color: 'red'}}>{errorText}</FormHelperText>
    </FormControl>
  );
};

const EditLotDialog = (props) => {

  const classes = useStyles();

  const submitForm = (formProps) => {
    props.reset();
    props.editLot(formProps);
    props.update();
    props.close();
  }

  useEffect(() => {
    props.initialize({
      lotId: props.lot.lotId,
      lotNumber: props.lot.lotNumber,
      colorCode: props.lot.colorCode,
      containers: props.lot.containers,
      lotState: props.lot.lotState
    });
  },[props.open]);
  

  return (
    <form>
      <Dialog open={props.open} onClose={props.close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Lot bearbeiten</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Felder mit * müssen ausgefüllt werden:
          </DialogContentText>
          <Grid container justify='center'>

            <Field
              name="lotNumber"
              component={renderTextField}
              label="Lotnummer"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={true}
            />

            <Field
              name="lotId"
              component={renderTextField}
              label="Freistellnummer"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={true}
            />

            <div className={classes.selectField}>
              <Field 
                name='containers'
                label='Container Anzahl*' 
                component={renderSelectField} 
                className={classes.textField} 
              >
                
                <MenuItem 
                  value={'5'}
                  label={'5'} 
                >
                  5
                </MenuItem>  

                <MenuItem 
                  value={'7'}
                  label={'7'} 
                >
                  7
                </MenuItem>
              
              </Field>
            </div>
            
            <Field
              name="colorCode"
              component={renderTextField}
              label="Farbe*"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              type="color"
              
            />
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="inherit">
            Abbrechen
          </Button>
          <Button 
            onClick={props.handleSubmit(submitForm)} 
            color='primary' 
            disabled={props.pristine || props.submitting || props.invalid}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default compose(
  reduxForm({
    form: 'EditLotDialog',
    validate
  }),
  connect(null, actions)
)(EditLotDialog);
