import React from 'react'
import { 
  List,
  ListItem,
  ListItemIcon, 
  ListItemText,
  CardContent,
  Paper,
} from '@material-ui/core';
import ActiveIcon from '@material-ui/icons/Build';
import InactiveIcon from '@material-ui/icons/Schedule';
import FinishedIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  root: {
    
    //position: 'absolute',
    
  },
  icon: {
    color: 'red'
  },
  paper: {
    height: '500px',
    padding: '5px',
    margin: '5px',
    overflow: 'auto'
  }
}));

export const LotList = (props) => {

  const classes = useStyles();

  const renderItemIcon = (lotState) => {
    switch (lotState) {
      case 'active' : 
        return <ActiveIcon color='inherit'/>;
      
      case 'inactive' : 
        return <InactiveIcon />;
      
      case 'finished' : 
        return <FinishedIcon />;
      
      default: 
        return <ActiveIcon />;
    }
  }
  
  const renderLots = (lot, index) => {

    const bgColor = lot.lotState === 'active' ? lot.colorCode : '';
    let statusLabel;

    switch(lot.lotState) {
      case 'active':
        statusLabel = 'aktiv'
        break;

      case 'inactive':
        statusLabel = 'inaktiv'
        break;

      case 'finished':
        statusLabel = 'abgeschlossen'
        break;

      default:
        statusLabel = 'inaktiv'
    }

    return (
      <ListItem 
        color={bgColor}
        
        button 
        key={index} 
        onClick={() => props.setItem(lot)}
      >
        <ListItemIcon style={{ color: bgColor }}>
          {renderItemIcon(lot.lotState)}
        </ListItemIcon>
        
        <ListItemText primary={`${lot.lotNumber} / ${lot.lotId}`} secondary={statusLabel} />

      </ListItem>
    )
  }

  return (
    <div className={classes.root}>
      <Paper elevation={4} className={classes.paper}>
        <CardContent>
          <List component="nav">
            {props.items.map(renderLots)}
          </List>
        </CardContent>
      </Paper>
    </div>
    
  );
}

export default LotList;