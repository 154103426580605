import React from 'react';
import requireAuth from '../components/hoc/requireAuth';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import Table from '../components/admin/Export/Table';

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop:'20px'
  }
}));


const ExportPage = (props) => {

  const classes = useStyles();

  return (
    <div>
      <Grid container justify='center'>
        <Typography variant='h4' className={classes.heading}>
          Suche / Export
        </Typography>
        <Grid item xs={12}>
          <Table />
        </Grid>
      </Grid>
    </div>
  );
}

export default requireAuth(ExportPage);