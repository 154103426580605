import React from 'react'
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/VpnKey';
import * as translators from '../util/tranlators';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    borderRadius: '2px',
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.primary.dark}`,
    padding: '0px',
    margin: '0px',
  },
  cardHeader: {
    padding: 5,
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardBody: {
    padding: '5px',
    borderTop: `2px solid ${theme.palette.primary.dark}`,
    borderBottom: `0.5px solid ${theme.palette.primary.dark}`,
    
  }, 
  cardFooter: {
    padding: '5px',
    fontSize: '14px'
  },  
  containerLabel: {
    textAlign: 'center',
    marginTop: '5px',
    fontSize: '14px',
  },
  sealId: {
    textAlign: 'left',
    fontSize: 13, 
    marginTop: 3  
  },
  box: {
    marginTop: 5, 
    textAlign: 'left', 
    color: 'grey', 
    fontSize: 15, 
    fontWeight: 'bold'
  },
  icon: {
    fontSize: 15,
    color: theme.palette.primary.dark
  }
  ,
  droppedDiv: {
    color: 'lime',
    fontWeight: 'bold'
  },
  iconDiv: {
    fontSize: '13px',
  }

  
}));

const ContainerCard = props => {

  const classes = useStyles(props);
    
  return (
    <div className={classes.root} onClick={() => {props.handleCardClick(props)}}>
      <header 
        className={classes.cardHeader}
        style={{
          backgroundColor: props.colorCode,
        }}>
        <div>{`${props.lotNumber} / ${props.lotId}`}</div>
      </header>
      <div className={classes.cardBody}>
        <div className={classes.containerLabel}>{props.id}</div>
        <div className={classes.box}>
          {translators.getBoxLabel(props.box)}
        </div>
      </div>
      <div className={classes.cardFooter}>
        <div className={classes.sealId}>
          {props.sealId === 'none' ? '' : <div className={classes.iconDiv}><CheckIcon className={classes.icon} /> {`${props.sealId}`}</div>}
        </div>
        <div className={classes.sealId}>
          {
            props.containerNumber !== undefined && props.containerNumber !== 0 && (
              `${props.containerNumber} von ${props.containers}`
            )
          }
        </div>
        <div className={classes.sealId}>
          {renderFixedStateLabel(props.fixedState)}
        </div>
      </div>
    </div>
  )
}



const renderFixedStateLabel = (state) => {
  if(state) {
    return (
      <span>{translators.getContainerStateLabel(state)}</span>
    )
  }   
}

export default ContainerCard;