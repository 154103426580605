import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Board from 'react-trello'
import { Grid, CircularProgress, Fab, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import MessageIcon from '@material-ui/icons/Message';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import * as actions from '../../actions/index';
import useWebSocket from '../hooks/useWebSocket';
import AddContainerDialog from './AddContainerDialog';
import * as apiConstants from '../../constants/api/constants';
import ContainerCard from './ContainerCard';
import EditContainerDialog from './EditContainerDialog';
import AlertDialog from '../util/AlertDialog';
import SendMessageDialog from './SendMessageDialog';
import * as boardUtils from './boardUtils';

import './board.css';
import ContainerNumberDialog from './ContainerNumberDialog';

const useStyles = makeStyles(theme =>({
  fab: {
    position: 'fixed',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.red,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    bottom: '20px',
    right: '20px',
  },
  messageFab: {
    position: 'fixed',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.red,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    bottom: '20px',
    right: '100px',
  },
  fileDownloadFab:{
    position: 'fixed',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.red,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    bottom: '20px',
    right: '180px',
  },
  loaderBackground: {
    position: 'absolute',
    backgroundColor: 'white',
    opacity: '0.8',
    top:'60px',
    width: '100vw',
    minHeight: 'calc(100vh - 60px)',
    zIndex: 1
  },
  loader: {
    margin: 'auto',
    width: '300px',
    textAlign: 'center'
  },
  board: {
    backgroundColor: '#607D8B',
    height: 'calc(100vh - 60px)',
    paddingRight: '100px'
  }
}));

const laneStyle = {
  fontFamily: 'roboto', 
  fontSize: '20px',
  maxHeight: '90vh',
  width: '180px',
  WebkitBoxShadow: '4px 4px 4px 0px rgba(0,0,0,0.75)',
  MozBoxShadow: '4px 4px 4px 0px rgba(0,0,0,0.75)',
  boxShadow: '4px 4px 4px 0px rgba(0,0,0,0.75)',  
  //backgroundColor: '#1D8A99'
}

const terminalLaneStyle = {
  fontFamily: 'roboto', 
  fontSize: '20px',
  maxHeight: '90vh',
  marginRight: '20px',
  width: '180px',
  WebkitBoxShadow: '4px 4px 4px 0px rgba(0,0,0,0.75)',
  MozBoxShadow: '4px 4px 4px 0px rgba(0,0,0,0.75)',
  boxShadow: '4px 4px 4px 0px rgba(0,0,0,0.75)',  
  //backgroundColor: '#1D8A99'
}

const cardStyle = {
  minWidth: '150px',
  maxWidth: '150px'
}


const initialData = {
  lanes: [
    {
      id: 'parking',
      title: 'PARKEN LEER',
      style: laneStyle,
      cards: []
    },
    {
      id: 'body1',
      title: 'BODY 1',
      style: laneStyle,
      cards: []
    },
    {
      id: 'rahmen1',
      title: 'RAHMEN 1',
      style: laneStyle,
      cards: []
    },
    {
      id: 'body3',
      title: 'BODY 3',
      style: laneStyle,
      cards: []
    },
    {
      id: 'rahmen2',
      title: 'RAHMEN 2',
      style: laneStyle,
      cards: []
    },
    {
      id: 'body2',
      title: 'BODY 2',
      style: laneStyle,
      cards: []
    },
    {
      id: 'kit1',
      title: 'KIT 1',
      style: laneStyle,
      cards: []
    },
    {
      id: 'kit2adr',
      title: 'KIT 2 ADR',
      style: laneStyle,
      cards: []
    },
    {
      id: 'moving',
      title: 'BEWEGUNG',
      style: laneStyle,
      cards: []
    },
    {
      id: 'terminal',
      title: 'TERMINAL',
      style: terminalLaneStyle,
      cards: []
    }
  ]
}

const initialContainerData = {
  containerId: '',
  lotId: '',
  containerState: '',
  sealId: '',
  box: false
}

const MagnaBoard = (props) => {

  const classes = useStyles();

  const [addContainerDialogOpen, setAddContainerDialogOpen] = useState(false);

  const [containerNumberDialog, setContainerNumberDialog] = useState({
    open: false, 
    previousValue: '0',
    lotContainers: 7,
    callback: null
  });
  
  const [editContainerDialogOpen, setEditContainerDialogOpen] = useState(false);

  const [sendMessageDialogOpen, setSendMessageDialogOpen] = useState(false);

  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false);

  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);

  const [downloadFileUrl, setDownloadFileUrl] = useState(null);

  const [selectedContainer, setSelectedContainer] = useState(initialContainerData);

  const [
    activeData, 
    newCardData, 
    updateCardData, 
    removeCardData,
    connectionOpen,
    sendWsMessage
  ] = useWebSocket();

  const [boardState, setBoardState] = useState({
    data: initialData,
    updateData: {},
    activeLots: [],
    detailsMap: undefined,
    eventBus: undefined
  });

  const setEventBusHandler = handle => {
    
    setBoardState(boardState => ({
      ...boardState,
      eventBus: handle
    }));
  }

  const sortTerminalLane = (boardData) => {
    boardData.lanes[9].cards = boardData.lanes[9].cards.sort((card1, card2) => {
      return card1.lotNumber - card2.lotNumber;
    });
    return boardData;
  }

  const shouldReceiveNewData = nextData => {
    
    nextData = sortTerminalLane(nextData);
    setBoardState(boardState => ({
      ...boardState,
      updateData: nextData
    }));
    
  }

  const addNewContainerCard = (container) => {
    
    //console.log('containers: ', boardState.detailsMap.get(container.lotId).containers);
    boardState.eventBus.publish({
      type: 'ADD_CARD',
      laneId: container.containerState,
      card: {
        id: container.containerId, 
        containerId: container.containerId, 
        lotId: container.lotId,
        lotNumber: boardState.detailsMap.get(container.lotId).lotNumber,
        box: container.box,
        containerState: container.containerState,
        containerNumber: container.containerNumber,
        fixedState: container.fixedState,
        sealId: container.sealId,
        colorCode: boardState.detailsMap.get(container.lotId).colorCode,
        containers: boardState.detailsMap.get(container.lotId).containers,
        lastModifiedBy: container.lastModifiedBy,
        lastModifiedTime: container.lastModifiedTime,
        cardStyle: cardStyle
      }
    });
    
  }

  const removeContainerCard = (container) => {
    
    let previousLane = container.previousState ? container.previousState : '';
    let newLane = container.containerState;
    boardState.eventBus.publish({
      type: 'REMOVE_CARD',
      laneId: previousLane,
      cardId: container.containerId
    });
    boardState.eventBus.publish({
      type: 'REMOVE_CARD',
      laneId: newLane,
      cardId: container.containerId
    });
    
  }

  const updateContainerCard = (container) => {
    removeContainerCard(container);
    addNewContainerCard(container);    
  }

  const addContainer = (containerData) => {
    
    props.showNotification('Container wird hinzugefügt...', false);
    let data = {
      action: apiConstants.ADD_CONTAINER,
      data: containerData
    };
    sendWsMessage(data);
  }

  const updateContainer = (oldContainer, newContainer) => {
    let data = {
      action: apiConstants.UPDATE_CONTAINER,
      data: {
        oldContainer: oldContainer,
        newContainer: newContainer
      }
    };
    sendWsMessage(data);
  }

  const removeContainer = (containerData) => {
    
    props.showNotification('Container wurde gelöscht', false);
    let removeData = {
      action: apiConstants.DELETE_CONTAINER,
      data: containerData
    };
    sendWsMessage(removeData);
  }

  const editContainer = (oldContainer, newContainer) => {
    props.showNotification('Container wird bearbeitet', false);
    updateContainer(oldContainer, newContainer);
  }

  const updateBoard = (newData) => {

    
    
    
    boardState.eventBus &&
    boardState.eventBus.publish({
      type: 'UPDATE_LANES', 
      lanes: initialData.lanes
    });
    

    // flatten array
    let containers = [].concat.apply([], newData);
  
    // get all active lots
    let activeLots = containers
      .filter(container => { return container.containerId === 'details'});
    
    // map lotIds to lot details
    let detailsMap = new Map();
    activeLots.forEach(lot => {
      detailsMap.set(lot.lotId, {
        lotNumber:lot.lotNumber,
        colorCode:lot.colorCode,
        containers: lot.containers
      });
    });
    
    // set board state
    setBoardState(boardState => ({
      ...boardState,
      activeLots: activeLots,
      detailsMap: detailsMap
    }));

    // create all container cards
    let containerCards = containers
      .filter((container) => { return container.containerId !== 'details' })
      .map((container) => {
        return {
          id: container.containerId, 
          containerId: container.containerId,
          containerState: container.containerState,
          fixedState: container.fixedState,
          containerNumber: container.containerNumber,
          lotId: container.lotId,
          lotNumber: detailsMap.get(container.lotId).lotNumber,
          box: container.box,     
          sealId: container.sealId,
          colorCode: detailsMap.get(container.lotId).colorCode,
          containers: detailsMap.get(container.lotId).containers,
          lastModifiedBy: container.lastModifiedBy,
          lastModifiedTime: container.lastModifiedTime,
          cardStyle: cardStyle
        }
    });

    containerCards.forEach(card => {
      let laneId = card.containerState;
      boardState.eventBus.publish({
        type: 'ADD_CARD',
        laneId: laneId,
        card: card,
      })
    });
  
    
  }


  const handleCardClick = (data) => {
    setSelectedContainer({
      containerId: data.id,
      lotId: data.lotId,
      sealId: data.sealId,
      containerState: data.containerState,
      fixedState: data.fixedState,
      containerNumber: data.containerNumber,
      box: data.box,
      lastModifiedBy: data.lastModifiedBy,
      lastModifiedTime: data.lastModifiedTime
    });
    setEditContainerDialogOpen(true);
  }

  const handleDragStart = () => {
  }

  const handleDragEnd = async(cardId, sourceLaneId, targetLaneId, position, card) => {
   

    // no change
    if(targetLaneId === sourceLaneId) {
      return;
    }

    let newContainerState = targetLaneId;

    // determine fixedState by checking targetLane
    let newFixedState = boardUtils.getFixedStateByTargetLane(targetLaneId, card.fixedState);
    let newContainerNumber = card.containerNumber || '0';
    //let newContainerNumber = getContainerNumberFromUserInputAsync();
    try {
      let noInputLanes = [ 
        apiConstants.STATE_PARKING,
        apiConstants.STATE_MOVING,
        apiConstants.STATE_TERMINAL
      ]

      if(!noInputLanes.includes(targetLaneId)) {
        let lotContainers = boardState.detailsMap.get(card.lotId).containers;
        newContainerNumber = await getContainerNumberFromUserInput(card.containerNumber || '0', lotContainers);
        
      }
      
      let oldContainer = {
        containerId: card.id,
        containerNumber: card.containerNumber,
        containerState: card.containerState,
        previousState: sourceLaneId,
        fixedState: card.fixedState,
        lotId: card.lotId,
        sealId: card.sealId,
        box: card.box,
        lastModifiedBy: card.lastModifiedBy,
        lastModifiedTime: card.lastModifiedTime
      }
  
      let containerUpdate = {
        containerId: card.id,
        containerState: newContainerState,
        containerNumber: newContainerNumber,
        previousState: sourceLaneId,
        fixedState: newFixedState,
        lotId: card.lotId,
        sealId: card.sealId,
        box: card.box,
        lastModifiedBy: props.userName,
        lastModifiedTime: getCurrentTime()
      }
      
      updateContainer(oldContainer, containerUpdate);
    } 
    catch (error) {
      console.log('error: ', error);
    }
  }


  const getContainerNumberFromUserInput = (previousValue, lotContainers) => {
    return new Promise((resolve, reject) => {
      setContainerNumberDialog({
        open: true,
        previousValue: previousValue,
        lotContainers: lotContainers,
        callback: (data) => {resolve(data);}
      });
    });
  }

  

  const getCurrentTime = () => {
    return Date.now();
  }

  // receive board update
  useEffect(() => {
    if(activeData) {
      updateBoard(activeData);
    }
  }, [activeData]);

  // receive new card
  useEffect(() => {
    if(newCardData) {
      addNewContainerCard(newCardData);
    }
  }, [newCardData]);
  
  // receive card update
  useEffect(() => {
    if(updateCardData) {
      updateContainerCard(updateCardData);
    }
  }, [updateCardData]);

  // receive card deletion
  useEffect(() => {
    if(removeCardData) {
      removeContainerCard(removeCardData);
    }
  }, [removeCardData]);


  

  const renderLoader = () => {
    if(!activeData || !connectionOpen) {
      return (
        <Grid container className={classes.loaderBackground} justify='center'>
          <div className={classes.loader}>
            <CircularProgress size={90} />
            <Typography variant='h5'>
              Verbindung wird aufgebaut
            </Typography>
          </div>
        </Grid>
      );
    }
  }

  const downloadPlanningFile = async() => {
    setFileDownloadLoading(true);
    let url = await boardUtils.downloadPlanningFile();
    setDownloadFileUrl(url);
    setFileDownloadLoading(false);
  }

  return (
    <div>
      { renderLoader() }
      <Board 
        data={boardState.data} 
        laneDraggable={false}
        draggable
        className={classes.board}
        eventBusHandle={setEventBusHandler}
        onDataChange={shouldReceiveNewData}
        handleDragStart={handleDragStart}
        handleDragEnd={handleDragEnd}
        customCardLayout
        //laneSortFunction={laneSortByLotNumber}
      >
        <ContainerCard handleCardClick={handleCardClick}/>
      </Board>

      <Tooltip title="Vorplanung herunterladen">
        <Fab 
          className={classes.fileDownloadFab} 
          color='inherit'
          onClick={downloadPlanningFile}
        >
          { fileDownloadLoading ? <CircularProgress /> : <DownloadIcon />}
        </Fab>
      </Tooltip>

      <Tooltip title="Nachricht senden">
        <Fab 
          className={classes.messageFab} 
          color='inherit'
          onClick={() => setSendMessageDialogOpen(true)}
        >
          <MessageIcon />
        </Fab>
      </Tooltip>

      <Tooltip title="Container hinzufügen">
        <Fab 
          className={classes.fab} 
          color='inherit'
          onClick={() => setAddContainerDialogOpen(true)}>
          <AddIcon />
        </Fab>
      </Tooltip>

      <AddContainerDialog 
        open={addContainerDialogOpen}
        close={() => setAddContainerDialogOpen(false)}
        addContainer={addContainer}
        activeLots={boardState.activeLots}
        userName={props.userName}
      />
      <EditContainerDialog
        open={editContainerDialogOpen}
        close={() => setEditContainerDialogOpen(false)}
        editContainer={editContainer}
        activeLots={boardState.activeLots}
        container={selectedContainer}
        deleteContainer={() => setRemoveConfirmationOpen(true)}
        userName={props.userName}
        lotContainers={
          selectedContainer.lotId ? 
          boardState.detailsMap.get(selectedContainer.lotId).containers :
          7
        }
      />
      <SendMessageDialog 
        open={sendMessageDialogOpen}
        close={() => setSendMessageDialogOpen(false)}
        userName={props.userName}
      />
      <ContainerNumberDialog 
        open={containerNumberDialog.open}
        callback={containerNumberDialog.callback}
        previousValue={containerNumberDialog.previousValue}
        lotContainers={containerNumberDialog.lotContainers}
        close={() => setContainerNumberDialog({
          open: false, 
          previousValue: '0', 
          lotContainers: 7,
          callback: null})}
  
      />
      <AlertDialog 
        open={removeConfirmationOpen}
        close={() => setRemoveConfirmationOpen(false)}
        accept={() => {
          removeContainerCard(selectedContainer);
          setRemoveConfirmationOpen(false);
          removeContainer(selectedContainer);
        }}
        userName={props.userName}
        title={`Container ${selectedContainer.containerId} löschen`}
        text={`Wollen Sie den Container ${selectedContainer.containerId} (Lot: ${selectedContainer.lotId}) wirklich löschen?`}
      />
      <div style={{display: 'none'}}>
        <iframe src={downloadFileUrl} title='downloadFrame' />
      </div>
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName
  }
}

export default connect(mapStateToProps, actions)(MagnaBoard);