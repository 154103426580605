import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';

import EnhancedTableHead from './TableHead';
import EnhancedTableToolbar from './TableToolbar';
import * as translators from '../../util/tranlators';
import ContainerDialog from './ContainerDialog';
import * as actions from '../../../actions/index';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  hoverItem: {
    '&&:hover': {
      cursor: 'pointer'
    }
  }
}));

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



const initialLotData = [
  { lotId:'', containers: null, lotNumber:	'', lotState:''}
];

const translate = (lots) => {
  let translatedLots = lots.map((lot) => {
    return {
      ...lot,
      lotState: translators.getLotStateLabel(lot.lotState),
      created: new Date(lot.created).toLocaleString(),
    };
  });

  return translatedLots;
}


const EnhancedTable = (props) => {
  
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('lotId');
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = React.useState(initialLotData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [containerDialogOpen, setContainerDialogOpen] = React.useState({
    open:false,
    id: ''
  });

  useEffect(() => {
    setData(translate(props.lots));
  }, [props.lots])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      //const newSelecteds = data.map(n => n.lotId);
      const newSelecteds = data;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleCheckBoxClick = (event, lot) => {

    /*const selectedIndex = selected.indexOf(lot.lotId);
    
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, lot.lotId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }*/

    const selectedIndex = selected.indexOf(lot);
    
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, lot);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  const handleRowClick = (event, id) => {
    props.fetchSingleLot(id);
    setContainerDialogOpen({open: true, id: id});
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  }

  const isSelected = lot => selected.indexOf(lot) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);


  return (
    <Paper className={classes.root}>
      <EnhancedTableToolbar selected={selected} setData={setData}/>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(lot => {
                const isItemSelected = isSelected(lot);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={lot.lotId}
                    selected={isItemSelected}
                    className={classes.hoverItem}
                  >
                    <TableCell 
                      padding="checkbox"
                      onClick={event => handleCheckBoxClick(event,lot)}
                    >
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    
                    <TableCell 
                      component="th" 
                      scope="row" 
                      padding="none"
                      onClick={event => handleRowClick(event,lot.lotId)}>
                      {lot.lotId}
                    </TableCell>
                    <TableCell 
                      onClick={event => handleRowClick(event,lot.lotId)}
                    >{lot.lotNumber}</TableCell>
                    <TableCell 
                      onClick={event => handleRowClick(event,lot.lotId)}
                    >{lot.containers}</TableCell>
                    <TableCell 
                      onClick={event => handleRowClick(event,lot.lotId)}
                    >{lot.lotState}</TableCell>
                    
                    
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelRowsPerPage='Zeilen pro Seite'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Vorherige Seite',
        }}
        nextIconButtonProps={{
          'aria-label': 'Nächste Seite',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ContainerDialog 
        open={containerDialogOpen.open} 
        lotId={containerDialogOpen.id}
        handleClose={() => {setContainerDialogOpen({open: false, id:''})}}
      />
    </Paper>
  );  
  
}

const mapStateToProps = (state) => {
  return {
    lots: state.export.lots,
    fetching: state.export.fetching
  }
}

export default connect(mapStateToProps, actions)(EnhancedTable);
