import * as constants from '../constants/lots/constants';

const INITIAL_STATE = {
  lots: [],
  fetching: false,
  error: ''
};

export default function(state=INITIAL_STATE, action) {

  switch(action.type) {

    case constants.GET_ALL_LOTS_REQUEST: 
      return {
        ...state,
        fetching: true,
        error: ''
      };
    
    case constants.GET_ALL_LOTS_SUCCESS: 
      return {
        lots: action.payload,
        fetching: false,
        error: ''
      };

    case constants.GET_ALL_LOTS_ERROR: 
      return {
        ...state,
        fetching: false,
        error: action.payload
      };

    case constants.ADD_LOT_REQUEST: 
      return {
        ...state,
        fetching: true,
        error: ''
      };
    
    case constants.ADD_LOT_SUCCESS: 
      return {
        lots: action.payload,
        fetching: false,
        error: ''
      };

    case constants.ADD_LOT_ERROR: 
      return {
        ...state,
        fetching: false,
        error: action.payload
      };

    default:
        return state;
  }
}