import * as constants from '../constants/notifications/constants';

export const showNotification = (message, error) => async dispatch => {
  dispatch({
    type: constants.NOTIFICATION_SHOW,
    payload: {
      message,
      error
    }
  });
}

export const hideNotification = () => async dispatch => {
  dispatch({
    type: constants.NOTIFICATION_HIDE
  });
}