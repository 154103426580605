import axios from 'axios';

import * as authService from './AuthService';
import { API_URL } from '../constants/api/constants';

export const sendMailToAdmin = async (content) => {
  let url = `${API_URL}/mail`;
  let body = JSON.stringify({
    userName: content.userName,
    message: content.messageContent
  });

  try {
    let headers = await authService.getAuthHeaders();
    return axios.post(url, body, {headers: headers});
  } 
  catch (error) {
    console.log('error: ', error);
    authService.logout();
  }
}