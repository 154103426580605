import React from 'react'
import { 
  List,
  ListItem,
  ListItemIcon, 
  ListItemText,
  CardContent,
  Paper,
} from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountBox';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  root: {
    
    //position: 'absolute',
    
  },
  icon: {
    color: 'red'
  },
  paper: {
    height: '500px',
    padding: '5px',
    margin: '5px',
    overflow: 'auto'
  }
}));

export const ManagementList = (props) => {
  const classes = useStyles();
  
  const renderItems = (item, index) => {
    return (
      <ListItem 
        button 
        key={index} 
        onClick={() => props.setItem(item)}
      >
        <ListItemIcon>
          <AccountIcon />
        </ListItemIcon>
        <ListItemText primary={item} />
      </ListItem>
    )
  }

  return (
    <div>
      <Paper elevation={4} className={classes.paper}>
        <CardContent>
          <List component="nav">
            {props.items.map(renderItems)}
          </List>
        </CardContent>
      </Paper>
    </div>
  );
}

export default ManagementList;