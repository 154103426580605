import React from 'react';

import requireAuth from '../components/hoc/requireAuth';
import MagnaBoard from '../components/board/MagnaBoard';

const BoardPage = () => {

  return (
    <div>
      <MagnaBoard />
    </div>
  );
}

export default requireAuth(BoardPage);