import React, { useEffect, useState } from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Grid,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  textField: {
    width: '300px',
  },
  group: {
    marginTop: '10px'
  },
  selectField: {
    marginTop: '18px'
  },
  checkbox: {
    width: '300px'    
  },
  smallTextField: {
    width: '200px'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem'
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.red
    }
  },
}));


const ContainerNumberDialog = (props) => {

  const classes = useStyles();
  const [containerNumber, setContainerNumber] = useState('0');
  
  useEffect(() => {
    
  //},[props.open]);
  },[]);

  const handleSubmit = () => {
    props.callback(containerNumber);
    props.close();
  }

  const handleClose = () => {
    props.callback(props.previousValue);
    props.close();
  }

  const handleChange = (event) => {
    setContainerNumber(event.target.value);
  }

  
  
  return (
      <Dialog 
        open={props.open} 
        onClose={props.close} 
        aria-labelledby="containerNumberDialog"
        fullScreen
      >
        <DialogTitle id="containerNumberDialogTitle">Containernummer festlegen</DialogTitle>
        <DialogContent>

          <Grid container justify='center'>

            <Grid item xs={12}>
              <Grid container justify='center'>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Laufende Containernummer:</FormLabel>
                    <RadioGroup
                      aria-label="containerNumber"
                      name="containerNumber1"
                      className={classes.group}
                      value={containerNumber}
                      onChange={handleChange}
                      row={true}
                      style={{ display: 'flex' }}
                    >
                      <FormControlLabel value='1' control={<Radio />} label='1' labelPlacement='bottom' />
                      <FormControlLabel value='2' control={<Radio />} label='2' labelPlacement='bottom' />
                      <FormControlLabel value='3' control={<Radio />} label='3' labelPlacement='bottom' />
                      <FormControlLabel value='4' control={<Radio />} label='4' labelPlacement='bottom' />
                      <FormControlLabel value='5' control={<Radio />} label='5' labelPlacement='bottom' />
                      <FormControlLabel 
                        value='6' 
                        control={<Radio />} 
                        label='6' 
                        labelPlacement='bottom'
                        disabled={props.lotContainers !== '7'}
                      />
                      <FormControlLabel 
                        value='7' 
                        control={<Radio />} 
                        label='7' 
                        labelPlacement='bottom' 
                        disabled={props.lotContainers !== '7'}
                      /> 
                    </RadioGroup>
                  </FormControl>
              </Grid>
            </Grid>

            
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Abbrechen
          </Button>
          <Button 
            onClick={handleSubmit} 
            color='primary' 
            disabled={containerNumber === 0}
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default ContainerNumberDialog;