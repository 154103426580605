import * as constants from '../constants/users/constants';

const INITIAL_STATE = {
  user: '',
  userAttributes: [],
  isFetching: false,
  error: ''
};

export default function(state=INITIAL_STATE, action) {

  switch(action.type) {

    case constants.GET_USER_REQUEST: 
      return {
        ...state,
        isFetching: true,
        error: ''
      }

    case constants.GET_USER_SUCCESS: 
      return {
        user: action.payload.user,
        userAttributes: action.payload.userAttributes,
        isFetching: false,
        error: ''
      }
      
    case constants.GET_USER_ERROR: 
      return {
        user: '',
        isFetching: false,
        error: action.payload
      }
    
    case constants.UPDATE_USER_REQUEST: 
      return {
        ...state,
        isFetching: true
      };
    
    case constants.UPDATE_USER_SUCCESS: 
      return INITIAL_STATE;
    
    case constants.UPDATE_USER_ERROR: 
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    
    default:
      return state;
  }
}