export const AUTH_LOGIN_REQUEST = 'auth_login_request';
export const AUTH_LOGIN_SUCCESS = 'auth_login_success';
export const AUTH_LOGIN_SUCCESS_NEWPW = 'auth_login_success_newpw';
export const AUTH_LOGIN_SUCCESS_RESETPW = 'auth_login_success_resetpw';
export const AUTH_LOGIN_ERROR = 'auth_login_error';

export const AUTH_LOGIN_NEWPW_REQUEST = 'auth_login_newpw_request';
export const AUTH_LOGIN_NEWPW_SUCCESS = 'auth_login_newpw_success';
export const AUTH_LOGIN_NEWPW_ERROR = 'auth_login_newpw_error';

export const AUTH_SIGNIN_RESPONSE_SUCCESS = 'auth_signin_response_success';
export const AUTH_SIGNIN_RESPONSE_NEWPW = 'auth_signin_response_newpw';
export const AUTH_SIGNIN_RESPONSE_RESET = 'auth_signin_response_reset';