import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Toolbar, Tooltip, Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import SearchBar from './SearchBar';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { connect } from 'react-redux';

import * as exportActions from '../../../actions/exportActions';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  selectedLabel: {
    transition: 'all ease-in-out 0.2s',
    marginLeft: '10px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    width: '200px'
  },
  title: {
    flex: '0 0 auto',
  },
}));



const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selected } = props;

  const renderXlsButtonContent = () => {
    if(props.xlsFetching) {
      return <CircularProgress size={24}/>;
    }
    else {
      return (
        <Fragment>
          <DownloadIcon style={{marginRight: 5}} />
          XLS
        </Fragment>
      );
    }
  }

  const renderPdfButtonContent = () => {
    if(props.pdfFetching) {
      return <CircularProgress size={24} />;
    }
    else {
      return (
        <Fragment>
          <DownloadIcon style={{marginRight: 5}} />
          PDF
        </Fragment>
      )
    }
  }

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: selected.length > 0,
      })}
    >
      <Tooltip
        title="Suchen Sie nach Freistellnummer, Lotnummer oder Containernummer"
        placement={'bottom-end'}
        enterDelay={100}
      >
        <div className={classes.searchBar}>
          <SearchBar setData={props.setData} />
        </div>
      </Tooltip>
      <div className={classes.title}>
        {selected.length > 0 ? (
          <Typography color="inherit" variant="subtitle1" className={classes.selectedLabel}>
            {selected.length} Ausgewählt
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            
          </Typography>
        )}
      </div>
      
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {selected.length > 0 ? (
          <Grid container>

            <Grid item xs={6}>
              <Tooltip title="Als PDF downloaden">
                <Button 
                  aria-label="downloadpdf"
                  style={{marginRight: 5}}
                  disabled={props.pdfFetching}
                  onClick={() => props.exportToPdf(selected)}
                >
                  {renderPdfButtonContent()}
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={6}>
              <Tooltip title="Als XLS downloaden">
                <Button 
                  style={{marginRight: 5}}
                  aria-label="downloadXLS"
                  disabled={props.xlsFetching}
                  onClick={() => props.exportToXls(selected)}
                >
                  {renderXlsButtonContent()}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        ) : ''}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    xlsFetching: state.export.xlsFetching,
    pdfFetching: state.export.pdfFetching
  }
}

export default connect(mapStateToProps, exportActions)(EnhancedTableToolbar);