import React from 'react';
import { 
  Grid,
  Typography, 
  Card,
  CardActionArea,
  CardMedia,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import requireAdminAuth from '../components/hoc/requireAdminAuth';
import { lotsPic, searchPic, userPic, boardPic } from '../static/index';

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: 20
  },
  panelGrid: {
    paddingTop: 20,
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.up('md')]: {
      paddingRight: 120,
      paddingLeft: 120,
    },
  },
  card: {
    maxWidth: 400,
    width: 600,
    margin: 10
  },
  media: {
    height: 140,
  },
}));

const cards = [
  {
    title: 'Board',
    description: 'Statusübersicht aller Container',
    img: boardPic,
    imgTitle: 'some containers',
    route: '/board'
  },
  {
    title: 'Lot Management',
    description: 'Lots hinzufügen, aktivieren und abschließen',
    img: lotsPic,
    imgTitle: 'some containers',
    route: 'lotManagement'
  },
  {
    title: 'User Management',
    description: 'User hinzufügen, löschen und Passwörter zurücksetzen',
    img: userPic,
    imgTitle: 'some containers',
    route: '/userManagement'
  },
  {
    title: 'Suche / Export',
    description: 'Exportieren Sie Lot- und Containerdaten',
    img: searchPic,
    imgTitle: 'some containers',
    route: '/export'
  },
]


const AdminPage = (props) => {
  
  const classes = useStyles();

  const handleCardClick = (route) => {
    props.history.push(route);
  }

  const renderCard = (cardProps, index) => {
    return (
      <Grid item xs={12} sm={6} key={index}>
        <Grid container justify='center'>
          <Card 
            className={classes.card} 
            onClick={() => handleCardClick(cardProps.route)}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={cardProps.img}
                title={cardProps.imgTitle}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {cardProps.title}
                </Typography>
                <Typography component="p">
                  {cardProps.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justify='center'>

      <Grid item className={classes.heading}>
        <Typography variant='h4'>
          Admin Panel
        </Typography>
      </Grid>

      <Grid container className={classes.panelGrid}>
        {cards.map(renderCard)}
      </Grid>

    </Grid>
  );
}

export default requireAdminAuth(AdminPage);