import React from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  TextField, 
  DialogActions, 
  Button, 
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as actions from '../../actions/index';


const useStyles = makeStyles(theme => ({
  textField: {
    width: '300px'
  },
}));

const validate = values => {
  const errors = {}
  const requiredFields = [
    'username',
    'email'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `Bitte ausfüllen`
    }
  })

  return errors;
}

const validateEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Keine korrekte Email-Addresse' : undefined;

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
  }) => {  
  const errorText = (error && touched) ? error : '';
  return (
    <TextField
      error={error && touched}
      label={label}
      helperText={errorText}
      {...input}
      {...custom}  
    />
  );
}



const AddUserDialog = (props) => {

  const classes = useStyles();

  const submitForm = (formProps) => {
    props.reset();
    props.addNewUser(formProps, props.getAllUsers);
    props.close();
  }

  return (
    <form>
      <Dialog open={props.open} onClose={props.close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">User hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Felder mit * müssen ausgefüllt werden:
          </DialogContentText>
          <Grid container justify='center'>
            <Field
              name="username"
              component={renderTextField}
              label="Username*"
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <Field
              name="email"
              validate={validateEmail}
              component={renderTextField}
              label="Email*"
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <Field
              name="name"
              component={renderTextField}
              label="Vorname"
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <Field
              name="familyName"
              component={renderTextField}
              label="Nachname"
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <Field
              name="workerId"
              component={renderTextField}
              label="Personalnummer"
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="inherit">
            Abbrechen
          </Button>
          <Button 
            onClick={props.handleSubmit(submitForm)} 
            color='primary' 
            disabled={props.pristine || props.submitting || props.invalid}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default compose(
  reduxForm({
    form: 'AddUserDialog',
    validate
  }),
  connect(null, actions)
)(AddUserDialog);
