import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import auth from './auth';
import notification from './notification';
import lots from './lots';
import users from './users';
import singleUser from './singleUser';
import exportReducer from './export';

export default combineReducers({
  auth,
  notification,
  lots,
  users,
  singleUser,
  form: formReducer,
  export: exportReducer
});