import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { CircularProgress, Typography, Grid, Button, Paper } from '@material-ui/core';
import red from '@material-ui/core/colors/red';

import * as actions from '../../actions/index';
import { makeStyles } from '@material-ui/styles';
import AlertDialog from '../util/AlertDialog';

const useStyles = makeStyles(theme => ({
  buttonGrid: {
    
  },
  paper: {
    minHeight: '400px',
    padding: '15px',
    margin: '5px'
  },
  errorButton: {
    margin: 5,
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[800]
    }
  },
  pwButton: {
    margin: 5,
    color: theme.palette.getContrastText(red[500]),
  }
}));


const UserManagementDetails = (props) => {

  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [pwDialogOpen, setPwDialogOpen] = useState(false);

  useEffect(() => {
    if(props.selectedUser) {
      props.getSingleUser(props.selectedUser);      
    }
  },[props.selectedUser]);

  
  const resetPw = () => {
    setPwDialogOpen(false);
    props.resetPwForSingleUser(props.user.user, props.user.userAttributes, props.getAllUsers);
  }

  const deleteUser = () => {
    setDeleteDialogOpen(false);
    props.deleteSingleUser(props.user.user, props.getAllUsers);
  }

  const requestResetPw = () => {
    setPwDialogOpen(true);
  }

  const requestDeleteUser = () => {
    setDeleteDialogOpen(true);
  }
  
  const renderLoader = () => {
    return(
      <CircularProgress />
    );
  }

  const renderAttributes = () => {

    let { name, family_name, email } = props.user.userAttributes;

    return (
      <div>
        <Typography variant='h6' color='textPrimary'>
          Vorname: {name}
        </Typography>
        <Typography variant='h6' color='textPrimary'>
          Nachname: {family_name}
        </Typography>
        <Typography variant='h6' color='textPrimary'>
          Email: {email}
        </Typography>
        <Typography variant='h6' color='textPrimary'>
          PersNr: {props.user.userAttributes['custom:workerId']}
        </Typography>
      </div>
    );
  }

  const renderUser = () => {
    if(props.user.user) {
      return (
        <div>
          <Grid container>

            <Grid item xs={12}>
              <Typography variant='h5' color='primary'>
                Username: {props.user.user}
              </Typography>
              { renderAttributes() }
            </Grid>

            <Grid item xs={12}>
              <Grid container className={classes.buttonGrid}>
                <Button 
                  color='secondary' 
                  variant='contained' 
                  className={classes.pwButton}
                  onClick={requestResetPw}
                >
                  Passwort<br />zurücksetzen
                </Button>
                <Button 
                  variant='contained' 
                  color='inherit' 
                  className={classes.errorButton}
                  onClick={requestDeleteUser}
                >
                  Löschen
                </Button>
              </Grid>
            </Grid>
            <AlertDialog 
              open={deleteDialogOpen} 
              accept={deleteUser} 
              close={() => setDeleteDialogOpen(false)}
              title='User löschen'
              text={`Wollen Sie den User ${props.user.user} wirklich löschen?`}
            />
            <AlertDialog 
              open={pwDialogOpen} 
              accept={resetPw} 
              close={() => setPwDialogOpen(false)}
              title='Passwort zurücksetzen'
              text={`Wollen Sie das Passwort von ${props.user.user} wirklich zurücksetzen?`}
            />
          </Grid>
        </div>
      );
    }
    else {
      return (
        <div>
          <Typography variant='h6'>
            Bitte wählen Sie einen User aus der Liste
          </Typography>
        </div>
      );
    }
  }

  return (
    <div>
      <Paper elevation={4} className={classes.paper}>
        {props.user.isFetching ? renderLoader() : renderUser()}
      </Paper>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.singleUser
  };
}


export default connect(mapStateToProps, actions)(UserManagementDetails);
