import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

import * as actions from '../../actions/index';
import { logo } from '../../static/index';
import SideMenu from './SideMenu';

const useStyles = makeStyles( theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  logoContainer: {
    width: '150px',
    height: '50px',
    flexGrow: 3,
    backgroundImage: `url(${logo})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: theme.palette.secondary.main,
  },
  userName: {
    color: theme.palette.secondary.red,
    fontWeight: '500'
  }
  
}));


const HeaderBar = (props) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [menuOpen, setMenuOpen] = React.useState(false);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }



  const logout = () => {
    setAnchorEl(null);
    props.logout();
  }

  

  const renderMenu = () => {
    if(props.auth.authenticated) {
      return (
        <IconButton 
          className={classes.menuButton} 
          color="inherit" 
          //aria-label="Menu"
          onClick={() => setMenuOpen(true)}>
          <MenuIcon />
        </IconButton>
      );
    }
    else return;
  }

  const renderUserItems = () => {
    if(props.auth.authenticated) {
      return (
        <>
          <Typography variant='body1' color='secondary'>
            Angemeldet als: <span className={classes.userName}>{props.auth.userName}</span>
          </Typography>
          <IconButton
            aria-owns={open ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={handleMenu}
            color="secondary"
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </>
      );
    }
    else return;
  }
  
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {renderMenu()}
          <div className={classes.logoContainer} />
          {renderUserItems()}
        </Toolbar>
      </AppBar>
      <SideMenu 
        open={menuOpen} 
        authRole={props.auth.role}
        close={() => setMenuOpen(false)}
        openDrawer={() => setMenuOpen(true)} 
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, actions)(HeaderBar);