import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as actions from '../../actions/index';
import { Grid, TextField, Button, CircularProgress, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '300px'
  },
  centeredItem: {
    margin: 'auto'
  },
  submitButton: {
    marginTop: '20px'
  },
  errorMessage: {
    color: theme.status.error
  }
}));

const validate = (values) => {
  const errors = {}
  const requiredFields = [
    'password',
    'passwordAgain'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `Bitte Passwort ausfüllen`
    }
  });

  if(values['password'] !== values['passwordAgain']) {
    let errString = 'Passwörter müssen übereinstimmen';
    errors['password'] = '';
    errors['passwordAgain'] = errString;
  }

  if(values['password']) {
    let regx = values['password'].match(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}/g);
    if(!regx) {
      errors['password'] = 'Passwort erfüllt die Kriterien nicht';
      errors['passwordAgain'] = '';
    }
  }

  return errors;
}


const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
  }) => {  
  const errorText = (error && touched) ? error : '';
  return (
    <TextField
      error={error && touched}
      label={label}
      helperText={errorText}
      {...input}
      {...custom}  
    />
  );
}

const NewPwForm = (props) => {

  const classes = useStyles();
  const { 
    handleSubmit, 
    pristine, 
    reset, 
    submitting, 
    setNewPassword,
    invalid,
    auth
  } = props;


  const submitForm = (formProps) => {
    reset();
    setNewPassword(formProps);
  }

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(submitForm)}>

      <Grid container>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Field 
              name="password"
              label="Passwort"
              className={classes.textField}
              component={renderTextField}
              margin="normal"
              variant="outlined"
              type="password"
              disabled={auth.isAuthenticating}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Field 
              name="passwordAgain"
              label="Passwort erneut eingeben"
              className={classes.textField}
              component={renderTextField}
              margin="normal"
              variant="outlined"
              type="password"
              disabled={auth.isAuthenticating}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Typography variant='body1' className={classes.errorMessage}>
              {auth.errorMessage}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Button 
                type='submit'
                variant='contained'
                size='large'
                color='secondary'
                className={classes.submitButton}
                disabled={pristine || submitting || invalid || auth.isAuthenticating}>
                {(auth.isAuthenticating) ? <CircularProgress size={24} /> : "Login"}
            </Button>
          </Grid>
        </Grid>

      </Grid>

    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

export default compose(
  reduxForm({
    form: 'NewPWForm',
    validate
  }),
  connect(mapStateToProps, actions)
)(NewPwForm);
