import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

import * as actions from '../../actions/index';


const useStyles = makeStyles( theme => ({
  
  close: {
    padding: '5px'
  },
  errorText: {
    color: theme.status.error
  },
  successText: {
    color: theme.status.success
  }
}));


const NotificationBar = (props) => {

  const classes = useStyles();
  const textClass = props.notification.error ? classes.errorText : classes.successText;

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={props.notification.active}
        autoHideDuration={6000}
        onClose={props.hideNotification}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span 
            id="message-id"
            className={textClass}>
              {props.notification.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={props.hideNotification}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification
  };
}

export default connect(
  mapStateToProps,
  actions
)(NotificationBar);