export const FETCH_QUERY_REQUEST = 'fetch_query_request';
export const FETCH_QUERY_SUCCESS = 'fetch_query_success';
export const FETCH_QUERY_ERROR = 'fetch_query_error';

export const FETCH_SINGLE_LOT_REQUEST = 'fetch_single_lot_request';
export const FETCH_SINGLE_LOT_SUCCESS = 'fetch_single_lot_success';
export const FETCH_SINGLE_LOT_ERROR = 'fetch_single_lot_error';

export const XLS_REQUEST = 'xls_request';
export const XLS_FINISH = 'xls_finish';
export const PDF_REQUEST = 'pdf_request';
export const PDF_FINISH = 'pdf_finish';
