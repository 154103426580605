import React, { useEffect } from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  TextField, 
  DialogActions, 
  Button, 
  Grid,
  RadioGroup,
  FormControlLabel,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
  FormControl,
  FormHelperText,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ClearIcon from '@material-ui/icons/Clear';

import * as actions from '../../actions/index';


const useStyles = makeStyles(theme => ({
  textField: {
    width: '300px',
  },
  selectField: {
    marginTop: '18px'
  },
  checkbox: {
    width: '300px'    
  },
  smallTextField: {
    width: '200px'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem'
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.red
    }
  },
}));

const useCheckBoxHelperClasses = makeStyles(theme => ({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem'
  }
}));

const validate = values => {
  const errors = {}
  const requiredFields = [
    'containerId',
    'lotId'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `Bitte ausfüllen`
    }
  });

  // convert containerId to uppercase
  if(values['containerId']) {
    values['containerId'] = values['containerId'].toUpperCase();
  } 


  var containerPattern = /^[A-Z]{4}[0-9]{7}$/g;

  if(values['containerId'] && !values['containerId'].match(containerPattern)) {
    errors['containerId'] = 'Containernummer muss aus 4 Großbuchstaben und 7 Ziffern bestehen';
  }
  
  return errors;
}

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
  }) => {  
  const errorText = (error && touched) ? error : '';
  return (
    <TextField
      error={error && touched}
      label={label}
      helperText={errorText}
      {...input}
      {...custom}  
    />
  );
}

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  const errorText = (error && touched) ? error : '';
  return(
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-lot-simple">Lot</InputLabel>
      <Select
        {...input}
        onChange={(event, index, value) => { input.onChange(event.target.value) }}
        children={children}
        input={
          <OutlinedInput
            id="outlined-lot-simple" 
            labelWidth={50}
          />
        }
        {...custom}
      />
      <FormHelperText style={{color: 'red'}}>{errorText}</FormHelperText>
    </FormControl>
  );
};

const renderRadioGroup = ({ input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    value={input.value}
    onChange={(event, value) => input.onChange(value)}
  />
)



const AddContainerDialog = (props) => {

  const classes = useStyles();
  const checkBoxHelperClasses = useCheckBoxHelperClasses();
  
  useEffect(() => {
    props.initialize({ 
      box: 'hc',
      lastModifiedBy: props.userName,
      lastModifiedTime: Date.now()
    });
  //},[props.open]);
  },[]);

  const submitForm = (formProps) => {
    
    formProps.box = formProps.box === 'hc' ? false : true;
    props.reset();
    formProps.sealId = formProps.sealId || 'none';
    formProps.lastModifiedTime = Date.now();
    props.addContainer(formProps);
    props.close();
  }
  
  return (
    <form>
      <Dialog 
        open={props.open} 
        onClose={props.close} 
        aria-labelledby="addContainer"
        fullScreen
      >
        <ClearIcon className={classes.closeIcon} onClick={props.close} />
        <DialogTitle id="addContainer">Container hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Felder mit * müssen ausgefüllt werden:
          </DialogContentText>
          <Grid container justify='center'>

            <Grid item xs={12}>
              <Grid container justify='center'>
                <Field
                  name="containerId"
                  component={renderTextField}
                  label="Containernummer*"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justify='center'>
                <Field
                  name="sealId"
                  component={renderTextField}
                  label="Siegelnummer"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            
            <Grid item xs={12}>
              <Grid container justify='center'>

                <div className={classes.selectField}>
                  <Field 
                    name='lotId'
                    label='lots' 
                    component={renderSelectField} 
                    className={classes.textField} 
                  >
                    {
                      props.activeLots.map((lot, index) => {
                        return(
                          <MenuItem 
                            key={index} 
                            value={lot.lotId} 
                            label={lot.lotId} 
                          >
                            {`${lot.lotNumber} / ${lot.lotId}`}
                          </MenuItem>
                        )
                      })  
                    }
                  </Field>
                </div>
              </Grid>
            </Grid>
            

            <Grid item xs={12}>
              <Grid container justify='center'>
                <Field name="box" id="box" component={renderRadioGroup}>
                  <FormControlLabel
                    value='box'
                    control={<Radio color="primary" />}
                    classes={checkBoxHelperClasses}
                    label="BOX"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value='hc'
                    control={<Radio color="primary" />}
                    classes={checkBoxHelperClasses}
                    label="HC"
                    labelPlacement="start"
                  />
                </Field>
              </Grid>
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="inherit">
            Abbrechen
          </Button>
          <Button 
            onClick={props.handleSubmit(submitForm)} 
            color='primary' 
            disabled={props.pristine || props.submitting || props.invalid}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default compose(
  reduxForm({
    form: 'AddContainerDialog',
    validate
  }),
  connect(null, actions)
)(AddContainerDialog);
