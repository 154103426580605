import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, Fab, Typography, CircularProgress, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/CloudUpload';

import * as actions from '../actions/index';
import requireAdminAuth from '../components/hoc/requireAdminAuth';
import LotManagementDetails from '../components/admin/LotManagementDetails';
import { LotList } from '../components/admin/LotList';
import AddLotDialog from '../components/admin/AddLotDialog';
import UploadPlanningDialog from '../components/admin/UploadPlanningDialog';

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: 30,
    marginBottom: 20
  },
  fab: {
    position: 'absolute',
    bottom: '30px',
    right: '30px',
    color: 'white'
  },
  fabUpload: {
    position: 'absolute',
    bottom: '30px',
    right: '100px',
    color: 'white'
  },
  rootGrid: {
    padding: 20
  },
  listGrid: {
    
    overflow: 'auto'
  },
  detailsGrid: {
  }
}));

const renderLoadingIndicator = () => {
  return (
    <Grid container justify='center'>
      <CircularProgress size={50}/>
    </Grid>
  );
}


const LotManagementPage = (props) => {
  const classes = useStyles();
  const { lots, fetching, error } = props.lots;


  const initialLot = {
    colorCode: "",
    containerId: "",
    containers: "",
    created: "",
    lotId: "",
    lotState: ""
  }
  const [selectedLot, setSelectedLot] = useState(initialLot);

  const [unfinishedLots, setUnfinishedLots] = useState([]);

  const [addLotDialogOpen, setAddLotDialogOpen] = useState(false);

  const [uploadPlanningDialogOpen, setUploadPlanningDialogOpen] = useState(false);

  useEffect(() => {
    props.getAllLots();
  }, []);

  useEffect(() => {
    if(lots) {
      let filteredLots = lots.filter( lot => { return lot.lotState !== 'finished'} );
      filteredLots.sort((a, b) => {
        if(a.lotState === 'inactive') {
          return 1;
        }
        else {
          return parseInt(a.lotNumber) < parseInt(b.lotNumber) ? 1 : -1;
        }
      });
      setUnfinishedLots(filteredLots);
    }
  }, [lots]);

  const setInitialLot = () =>{
    setSelectedLot(initialLot);
  }

  const renderList = () => {
    return (
      <Grid container className={classes.rootGrid}>
        <Grid item xs={4} className={classes.listGrid}>
          <LotList 
            items={unfinishedLots} 
            type='users' 
            setItem={setSelectedLot}
            errorMessage={error} 
          />
        </Grid>
      
        <Grid item xs={8} className={classes.detailsGrid}>
          <LotManagementDetails selectedLot={selectedLot} lotUpdated={() => setInitialLot()} />
        </Grid>

        <Tooltip title="Vorplanung hochladen">
          <Fab 
            className={classes.fabUpload} 
            color='secondary'
            onClick={() => setUploadPlanningDialogOpen(true)}
          >
            <UploadIcon />
          </Fab>
        </Tooltip>

        <Tooltip title="Lot hinzufügen">
          <Fab 
            className={classes.fab} 
            color='secondary'
            onClick={() => setAddLotDialogOpen(true)}>
            <AddIcon />
          </Fab>
        </Tooltip>

      </Grid>
    );
  }

  return (
    
    <Grid container justify='center'>
      <Grid item className={classes.heading}>
        <Typography variant='h4'>
          Lot Verwaltung
        </Typography>
      </Grid>

      {fetching ? renderLoadingIndicator() : renderList() }
      <AddLotDialog 
        open={addLotDialogOpen} 
        close={() => setAddLotDialogOpen(false)} 
      />
      <UploadPlanningDialog
        open={uploadPlanningDialogOpen} 
        close={() => setUploadPlanningDialogOpen(false)} 
      />

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    lots: state.lots
  };
}

export default connect(mapStateToProps, actions)(requireAdminAuth(LotManagementPage));