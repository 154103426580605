import axios from 'axios';

import { API_URL } from '../constants/api/constants';
import { store } from '../store';
import * as authService from './AuthService';


export const getAllLots = async() => {
  let url = `${API_URL}/lot`;
  try {
    let headers = await authService.getAuthHeaders();
    return axios.get(url, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
}

export const getLotById = async(lotId) => {
  let url = `${API_URL}/lot/${lotId}`
  try {
    let headers = await authService.getAuthHeaders();
    return axios.get(url, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
}

export const finishLot = async(lot) => {
  let url = `${API_URL}/lot`;

  let body = JSON.stringify({
    lotId: lot.lotId,
    lotNumber: lot.lotNumber,
    lotState: 'finished',
    colorCode: lot.colorCode || '#ffffff',
    containers: lot.containers || ''
  });

  try {
    let headers = await authService.getAuthHeaders();
    return axios.put(url, body, {headers: headers});
  }
  catch(err){
    authService.logout();
  }

  
}

export const activateLot = async(lot) => {
  
  let activeLots = store.getState().lots.lots.filter((lot) => {
    return lot.lotState === 'active'
  });

  console.log("activeLots: ", activeLots);

  if(activeLots.length > 29) {
    throw new Error('zu viele Lots aktiv');
  }

  let url = `${API_URL}/lot`;

  let body = JSON.stringify({
    lotId: lot.lotId,
    lotNumber: lot.lotNumber,
    lotState: 'active',
    colorCode: lot.colorCode || '#ffffff',
    containers: lot.containers || ''
  });

  try {
    let headers = await authService.getAuthHeaders();
    return axios.put(url, body, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }

  
}

export const addLot = async(lot) => {
  let url = `${API_URL}/lot`;
  let body = JSON.stringify({
    lotId: lot.lotId,
    lotNumber: lot.lotNumber,
    lotState: 'inactive',
    colorCode: lot.colorCode || '#ffffff',
    containers: lot.containers || '5'
  });

  try {
    let headers = await authService.getAuthHeaders();
    return axios.post(url, body, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
}

export const setLot = async(lot) => {
  let url = `${API_URL}/lot`;
  let body = JSON.stringify({
    lotId: lot.lotId,
    lotNumber: lot.lotNumber,
    lotState: lot.lotState,
    colorCode: lot.colorCode,
    containers: lot.containers
  });

  try {
    let headers = await authService.getAuthHeaders();
    return axios.put(url, body, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
}

export const deleteLot = async(lotId) => {
  let url = `${API_URL}/lot/${lotId}`;
  
  try {
    let headers = await authService.getAuthHeaders();  
    return axios.delete(url, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
}