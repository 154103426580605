import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import LoginForm from '../components/auth/LoginForm';
import NewPwForm from '../components/auth/NewPwForm';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  centeredItem: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    marginTop: '30px'
  }
}));


const renderLogin = (classes) => {
  return (
    <>
      <Typography 
        variant="subtitle1" 
        gutterBottom
        className={classes.centeredItem}
      >
        Bitte melden Sie sich an:
      </Typography>
      <LoginForm />
    </>
  );
}

const renderNewPwLogin = (classes) => {
  return (
    <>
      <Typography 
          variant="subtitle1" 
          gutterBottom
          className={classes.centeredItem}
      >
        Bei <strong>erstmaliger</strong> Anmeldung müssen Sie ein neues Passwort festlegen.
        <br />
        Passwort muss aus mind. 8 Zeichen bestehen:
        <br />
        Anforderungen: Zahl, Großbuchstabe, Kleinbuchstabe
      </Typography>
      <NewPwForm />
    </>
  );
}

const LoginPage = (props) => {
  const classes = useStyles();
  const { auth, history } = props;

  useEffect( () => {
    if(auth.authenticated) {
      history.push('/');
    }
  },[auth]);

  return (
    <div>
        <Typography 
          variant="h3" 
          gutterBottom
          className={classes.centeredItem}
          >
          Login
        </Typography>
        { auth.passwordResetRequired ? renderNewPwLogin(classes) : renderLogin(classes) }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps, null)(LoginPage);