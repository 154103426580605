import * as apiConstants from '../../constants/api/constants';

export const getLotStateLabel = (label) => {
  switch(label) {
    case 'active':
      return 'Aktiv';

    case 'inactive':
      return 'Inaktiv';

    case 'finished':
      return 'Abgeschlossen';

    default:
      return label;
  }
}

export const getContainerStateLabel = (stateName) => {

  switch(stateName) {

    case apiConstants.STATE_PARKING:
      return 'Parken LEER';
    
    case apiConstants.STATE_MOVING:
      return 'In Bewegung';

    case apiConstants.STATE_TERMINAL:
      return 'Terminal';

    case apiConstants.STATE_BODY1:
      return 'BODY 1';

    case apiConstants.STATE_BODY2:
      return 'BODY 2';

    case apiConstants.STATE_BODY3:
      return 'BODY 3';

    case apiConstants.STATE_RAHMEN1:
      return 'RAHMEN 1';

    case apiConstants.STATE_RAHMEN2:
      return 'RAHMEN 2';

    case apiConstants.STATE_KIT1:
      return 'KIT 1';

    case apiConstants.STATE_KIT2ADR:
      return 'KIT 2 ADR';
    
    default:
      return '';
  }
}

export const getSealIdLabel = (sealId) => {
  if(sealId === 'none') {
    return '';
  }
  else return sealId;
}

export const getBoxLabel =  (isBox) => {
  if(isBox) {
    return 'BOX';
  }
  else {
    return 'HC';
  }
}