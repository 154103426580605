import * as constants from '../constants/auth/constants';

const INITIAL_STATE = {
  authenticated: '',
  errorMessage: '',
  userName: '',
  role: 'user',
  isAuthenticating: false,
  passwordResetRequired: false
}

export default function(state=INITIAL_STATE, action) {
  
  switch(action.type) {

    case constants.AUTH_LOGIN_REQUEST: 
      return {
        ...state, 
        userName: '',
        isAuthenticating: true,
        passwordResetRequired: false,
      };

    case constants.AUTH_LOGIN_ERROR: 
      return {
        ...state, 
        authenticated: '',
        userName: '',
        isAuthenticating: false,
        passwordResetRequired: false,
        errorMessage: action.payload
      };
    
    case constants.AUTH_LOGIN_SUCCESS: 
      return {
        ...state, 
        isAuthenticating: false,
        passwordResetRequired: false,
        authenticated: action.payload.session, 
        userName: action.payload.user,
        role: action.payload.role,
        errorMessage: ''
      };
    
    case constants.AUTH_LOGIN_SUCCESS_NEWPW: 
      return {
        ...state, 
        isAuthenticating: false,
        passwordResetRequired: true,
        authenticated: action.payload, 
        errorMessage: ''
      };
    
    case constants.AUTH_LOGIN_NEWPW_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        passwordResetRequired: true,
        errorMessage: ''
      }
    
    
    case constants.AUTH_LOGIN_NEWPW_ERROR: 
      return {
        ...state,
        isAuthenticating: false,
        passwordResetRequired: true,
        errorMessage: action.payload
      }

    default:
      return state;

  }
  
  
}