export const GET_ALL_USERS_REQUEST = 'get_all_users_request';
export const GET_ALL_USERS_SUCCESS = 'get_all_users_success';
export const GET_ALL_USERS_ERROR = 'get_all_users_error';

export const ADD_USER_REQUEST = 'add_user_request';
export const ADD_USER_SUCCESS = 'add_user_success';
export const ADD_USER_ERROR = 'add_user_error';

export const GET_USER_REQUEST = 'get_user_request';
export const GET_USER_SUCCESS = 'get_user_success';
export const GET_USER_ERROR = 'get_user_error';

export const UPDATE_USER_REQUEST = 'update_user_request';
export const UPDATE_USER_SUCCESS = 'update_user_success';
export const UPDATE_USER_ERROR = 'update_user_error';

