import React from 'react'
import {
  TextField, 
  Button,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions/index';

const useStyles = makeStyles(theme => ({  
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '300px'
  },
  centeredItem: {
    margin: 'auto'
  },
  submitButton: {
    marginTop: '20px'
  },
  errorMessage: {
    color: theme.status.error,
    marginTop: '12'
  }
}));

const validate = values => {
  const errors = {}
  const requiredFields = [
    'username',
    'password'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `Bitte ausfüllen`
    }
  })

  return errors;
}

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
  }) => {  
  const errorText = (error && touched) ? error : '';
  return (
    <TextField
      error={error && touched}
      label={label}
      helperText={errorText}
      {...input}
      {...custom}  
    />
  );
}

const LoginForm = (props) => {

  const classes = useStyles();
  const { 
    handleSubmit, 
    pristine, 
    reset, 
    submitting, 
    login,
    invalid,
    auth,
  } = props;
  
  const submitForm = (formProps) => {
    reset();
    login(formProps);
  }

  return (
    <form 
      className={classes.container} 
      onSubmit={handleSubmit(submitForm)}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify='center'>
            <Field
              name="username"
              component={renderTextField}
              label="Username"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={auth.isAuthenticating}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Field
              name="password"
              component={renderTextField}
              label="Passwort"
              className={classes.textField}
              margin="normal"
              type="password"
              variant="outlined"
              disabled={auth.isAuthenticating}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Typography variant='body1' className={classes.errorMessage}>
              {auth.errorMessage}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Button 
              type='submit'
              variant='contained'
              size='large'
              color='secondary'
              className={classes.submitButton}
              disabled={pristine || submitting || invalid || auth.isAuthenticating}>
              {(auth.isAuthenticating) ? <CircularProgress size={24} /> : "Login"}
            </Button> 
          </Grid>
        </Grid>

      </Grid>
    </form>
  );

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

export default compose (
  reduxForm({
    form: 'LoginForm',
    validate,
  }),
  connect(mapStateToProps, actions),
  withRouter
)(LoginForm);
