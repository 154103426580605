import * as exportConstants from '../constants/export/constants';
import * as lotService from '../services/LotService';
import * as exportService from '../services/ExportService';
import * as notificationActions from './notificationActions';

export const getLotsByQuery = (query) => async dispatch => {

  dispatch({
    type: exportConstants.FETCH_QUERY_REQUEST
  });
  
  try {
    let result;

    if(!query || query === '') {
      result = await lotService.getAllLots();
    }
    else {
      result = await exportService.getLotsByQuery(query);
    }
    dispatch({
      type: exportConstants.FETCH_QUERY_SUCCESS,
      payload: result.data.data
    });
  }
  catch (err) {
    notificationActions.showNotification('Lots konnten nichten geladen werden', true);
    dispatch({
      type: exportConstants.FETCH_QUERY_ERROR,
      payload: err.message
    });
  }

}

export const fetchSingleLot = (lotId) => async dispatch => {

  dispatch({ type: exportConstants.FETCH_SINGLE_LOT_REQUEST });

  try {
    let result = await lotService.getLotById(lotId);
    dispatch({
      type: exportConstants.FETCH_SINGLE_LOT_SUCCESS,
      payload: result.data.data
    });
  }
  catch(err) {
    notificationActions.showNotification('Container konnten nicht geladen werden', true);
    dispatch({
      type: exportConstants.FETCH_SINGLE_LOT_ERROR,
      payload: err.msg
    });
  }

}

export const exportToXls = (lots) => async dispatch => {
  dispatch({
    type: exportConstants.XLS_REQUEST
  });

  const finishCallback = () => {
    dispatch({
      type: exportConstants.XLS_FINISH
    });
  }

  exportService.exportToXls(lots, finishCallback);
}

export const exportToPdf = (lots) => async dispatch => {
  dispatch({
    type: exportConstants.PDF_REQUEST
  });

  const finishCallback = () => {
    dispatch({
      type: exportConstants.PDF_FINISH
    });
  }

  exportService.exportToPdf(lots, finishCallback);
}