import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';

const fahrnerRed = '#d02630';
const fahrnerGreen = '#08574C';

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blueGrey,
    secondary: {
      main: fahrnerGreen,
      red: fahrnerRed
    }
  },
  status: {
    danger: '#ff5722',
    error: '#ff1744',
    success: '#76ff03'
  },
});