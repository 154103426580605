import { 
  CognitoUserPool, 
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';


import * as cognitoConstants from '../constants/cognito/constants';
import * as authConstants from '../constants/auth/constants';

const poolData = {
  UserPoolId : cognitoConstants.USER_POOL_ID,
  ClientId : cognitoConstants.CLIENT_ID
};

var userPool = new CognitoUserPool(poolData);
var cognitoUser;
var userAttributes;


export const getAuthenticatedUser = () => {
  return userPool.getCurrentUser();
}

export const logout = () => {
  getAuthenticatedUser().signOut();
}

export const getUserSession = () => {
  return new Promise( (resolve, reject) => {
    const user = getAuthenticatedUser();
    if(!user) {
      reject(false);
    }
    else {
      user.getSession( (err, session) => {
        if(err) {
          reject(false);
        }
        else {
          resolve(session);
        }
      })
    }
  });
}

export const getUserRole = () => {
  return new Promise( (resolve, reject) => {
    getUserSession().then(
      session => {
        let sessionIdInfo = session.getIdToken().decodePayload();
        let role = 'user';
        if(
          sessionIdInfo['cognito:groups'] &&
          sessionIdInfo['cognito:groups'].includes(cognitoConstants.ADMIN_ROLE)) {
            role = 'admin';
        }
        resolve(role)
      },
      err => {
        reject(err);
      }
    );
  });
}

export const getAuthToken = async() => {
  try {
    let session = await getUserSession();
    let token = session.getAccessToken().getJwtToken();
    return token;
  }
  catch(e) {
    console.log(e);
  }
  
}

export const getAuthHeaders = async() => {

  try {
    let session = await getUserSession();
    let token = session.getIdToken().getJwtToken();

    let headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    
    return headers;
  }
  catch(err) {
    console.log("error ", err);
  }
  
}


export const signIn = (username, password) => {

  var authenticationData = {
    Username : username,
    Password : password,
  };

  var authenticationDetails = new AuthenticationDetails(authenticationData);
    
  var userData = {
    Username : username,
    Pool : userPool
  };
  
  cognitoUser = new CognitoUser(userData);
  
  return new Promise( (resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
  
      onSuccess: function(result) {
        
        let successResponse = {
          type : authConstants.AUTH_SIGNIN_RESPONSE_SUCCESS,
          data: result 
        };
        resolve(successResponse);
      },
    
      onFailure: function(err) {
        console.log("Login Error: ", err);
        reject(err);
      },

      newPasswordRequired: function(_userAttributes, _requiredAttributes) {
        
        userAttributes = _userAttributes;
        let newPwResponse = {
          type : authConstants.AUTH_SIGNIN_RESPONSE_NEWPW
        };
        resolve(newPwResponse);
      }
    
    });
  });
}

export const setNewPassword = (newPassword) => {
  delete userAttributes.email_verified;
  delete userAttributes.phone_number_verified;
  return new Promise( (resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      
      onSuccess: function(result) {
        resolve(result);
      },
      
      onFailure: function(err) {
        reject(err);
      },
    });
  });
}






