import * as apiConstants from '../../constants/api/constants';

import * as fileService from '../../services/FileService';

export const getContainerNumberByTargetLaneId = (targetLaneId, previousValue) => {

  switch (targetLaneId) {

    case apiConstants.STATE_BODY1:
      return 1;

    case apiConstants.STATE_BODY2:
      return 5;

    case apiConstants.STATE_BODY3:
      return 3;

    case apiConstants.STATE_RAHMEN1:
      return 2;

    case apiConstants.STATE_RAHMEN2:
      return 4;
    
    case apiConstants.STATE_KIT1:
      return 6;

    case apiConstants.STATE_KIT2ADR:
      return 7;
    
    default:
      return previousValue;
  }
}

export const getFixedStateByTargetLane = (targetLaneId, previousFixedState) => {


  switch(targetLaneId) {

    case apiConstants.STATE_BODY1:
      return apiConstants.STATE_BODY1;

    case apiConstants.STATE_BODY2:
      return apiConstants.STATE_BODY2;
    
    case apiConstants.STATE_BODY3:
      return apiConstants.STATE_BODY3;

    case apiConstants.STATE_RAHMEN1:
      return apiConstants.STATE_RAHMEN1;
    
    case apiConstants.STATE_RAHMEN2:
      return apiConstants.STATE_RAHMEN2;

    case apiConstants.STATE_KIT1:
      return apiConstants.STATE_KIT1;

    case apiConstants.STATE_KIT2ADR:
      return apiConstants.STATE_KIT2ADR;

    default:
      return previousFixedState;
  }
}

export const downloadPlanningFile = async() => {
  try {
    let url = await fileService.reqestDownloadUrl();
    return url.data.downloadUrl;
  } catch (error) {
    console.log(error);
  }
}