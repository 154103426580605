import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { 
  Grid,
  Typography,
  CircularProgress,
  Fab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as actions from '../actions';
import requireAdminAuth from '../components/hoc/requireAdminAuth';
import ManagementList from '../components/admin/ManagementList';
import UserManagementDetails from '../components/admin/UserManagementDetails';
import AddUserDialog from '../components/admin/AddUserDialog';


const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: 30,
    marginBottom: 20
  },
  fab: {
    position: 'absolute',
    bottom: '30px',
    right: '30px',
    color: 'white'
  },
  rootGrid: {
    padding: 20
  },
  listGrid: {
    overflow: 'auto'
  },
  detailsGrid: {
  }
}));


const UserManagementPage = (props) => {


  const classes = useStyles();
  const { users, fetching, error } = props.users;
  const [selectedUser, setSelectedUser] = useState('');
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

  useEffect(() => {
    props.getAllUsers();
  }, []);


  const renderLoadingIndicator = () => {
    return (
      <Grid container justify='center'>
        <CircularProgress size={50}/>
      </Grid>
    );
  }

  const renderList = () => {
    return (
      <Grid container className={classes.rootGrid}>
        
        <Grid item xs={4} className={classes.listGrid}>
          <ManagementList 
            items={users} 
            type='users' 
            isFetching={true}//{fetching} 
            setItem={setSelectedUser}
            errorMessage={error} 
          />
        </Grid>

        <Grid item xs={8} className={classes.detailsGrid}>
          <UserManagementDetails selectedUser={selectedUser} />
        </Grid>
        <Fab className={classes.fab} color='secondary' onClick={() => setAddUserDialogOpen(true)}>
          <AddIcon />
        </Fab>

      </Grid>
    );
  }

  return (
    <Grid container justify='center'>

      <Grid item className={classes.heading}>
        <Typography variant='h4'>
          User Verwaltung
        </Typography>
      </Grid>

      {fetching ? renderLoadingIndicator() : renderList() }

      <AddUserDialog open={addUserDialogOpen} close={() => setAddUserDialogOpen(false)} />
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users
  };
}

export default connect(mapStateToProps, actions)(requireAdminAuth(UserManagementPage));
