import * as constants from '../constants/notifications/constants';

const INITIAL_STATE = {
  active: false,
  message: '',
  error: false
};

export default function(state=INITIAL_STATE, action) {

  switch(action.type) {

    case constants.NOTIFICATION_SHOW: 
      return {
        active: true,
        message: action.payload.message,
        error: action.payload.error
      };
    
    case constants.NOTIFICATION_HIDE: 
      return {
        active: false,
        message: ''
      };
    
      default:
        return state;
  }
}