import * as constants from '../constants/export/constants';

const INITIAL_STATE = {
  lots: [],
  fetching: false,
  xlsFetching: false,
  pdfFetching: false,
  error: '',
  singleLot: {
    containers: [],
    fetching: false,
    error: ''
  }
};

export default function(state=INITIAL_STATE, action) {

  switch(action.type) {

    case constants.FETCH_QUERY_REQUEST: 
      return {
        ...state,
        fetching: true,
        error: '',
        singleLot: {
          ...state.singleLot
        }
      };

    case constants.FETCH_QUERY_SUCCESS: 
      return {
        lots: action.payload,
        fetching: false,
        error: '',
        singleLot: {
          ...state.singleLot
        }
      };
    
    case constants.FETCH_QUERY_ERROR: 
      return {
        ...state,
        fetching: false,
        error: action.payload,
        singleLot: {
          ...state.singleLot
        }
      };

    case constants.FETCH_SINGLE_LOT_REQUEST:
      return {
        ...state,
        singleLot: {
          containers: [],
          fetching: true,
          error: ''
        }
      };
    
    case constants.FETCH_SINGLE_LOT_SUCCESS:
      return {
        ...state,
        singleLot: {
          containers: action.payload,
          fetching: false,
          error: ''
        }
      };

    case constants.FETCH_SINGLE_LOT_ERROR:
      return {
        ...state,
        singleLot: {
          containers: [],
          fetching: false,
          error: action.payload
        }
      };

    case constants.XLS_REQUEST: 
      return {
        ...state,
        xlsFetching: true,
        singleLot: {
          ...state.singleLot
        }
      };
    
    case constants.XLS_FINISH: 
      return {
        ...state,
        xlsFetching: false,
        singleLot: {
          ...state.singleLot
        }
      }

    case constants.PDF_REQUEST: 
      return {
        ...state,
        pdfFetching: true,
        singleLot: {
          ...state.singleLot
        }
      }

    case constants.PDF_FINISH: 
      return {
        ...state,
        pdfFetching: false,
        singleLot: {
          ...state.singleLot
        }
      }
    


    default:
        return state;
  }
}