import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { CircularProgress, Typography, Grid, Button, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import red from '@material-ui/core/colors/red';

import * as actions from '../../actions/index';
import * as lotService from '../../services/LotService';
import { makeStyles } from '@material-ui/styles';
import AlertDialog from '../util/AlertDialog';
import EditLotDialog from './EditLotDialog';
import * as apiConstants from '../../constants/api/constants';
import useWriteWebSocket from '../hooks/useWriteWebSocket';
import * as translators from '../util/tranlators';



const useStyles = makeStyles(theme => ({
  root: {
    
  },
  paper: {
    minHeight: '400px',
    padding: '15px',
    margin: '5px'
  },
  errorButton: {
    margin: 5,
    width: '150px',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[800]
    }
  },
  pwButton: {
    margin: 5,
    width: '150px',
    color: theme.palette.getContrastText(red[500]),
  }
}));

const LotManagementDetails = (props) => {

  const classes = useStyles();

  const [containers, setContainers] = useState({
    items: [],
    fetching: false,
    error: ''
  });

  const [sendMessage] = useWriteWebSocket();

  useEffect(() => {
    const fetchContainers = async () => {
      setContainers({
        ...containers, 
        fetching: true
      });
      try {
        let result = await lotService.getLotById(props.selectedLot.lotId);
        
        setContainers({
          items: result.data.data,
          fetching: false,
          error: ''
        });
      }
      catch (err) {
        setContainers({
          ...containers, 
          fetching: false,
          error: err.message
        });
      }
      
    };

    if(props.selectedLot.lotId) {
      fetchContainers();
    }
  }, [props.selectedLot.lotId]);

  const initialConfirmDialogState = {
    open: false,
    acceptFunction: null,
    title: '',
    message: ''
  }

  const [confirmDialog, setConfirmDialog] = useState(initialConfirmDialogState);
  const [editDialog, setEditDialog] = useState(false);
  
  const unsetConfirmDialog = () => {
    setConfirmDialog(initialConfirmDialogState);
  }

  const finishLot = async () => {
    unsetConfirmDialog();
    try {
      let res = await lotService.finishLot(props.selectedLot);
      console.log(res);
      updateBoard();
      props.lotUpdated();
      props.showNotification("Lot abgeschlossen", false);
      props.getAllLots();
    }
    catch(err) {
      console.log("Error:", {err});
      props.showNotification("Lot konnte nicht abgeschlossen werden", true);
    } 
  }

  const activateLot = async () => {
    unsetConfirmDialog();
    try {
      let res = await lotService.activateLot(props.selectedLot);
      console.log('lot activate response: ', res);
      updateBoard();
      props.lotUpdated();
      props.showNotification(`Lot aktiviert`, false);
      props.getAllLots();
    }
    catch(err) {
      console.log("Error:", {err});
      props.showNotification(`Lot konnte nicht aktiviert werden: ${err.message}`, true);
    } 
  }

  const deleteLot = async() => {
    unsetConfirmDialog();
    try {
      await lotService.deleteLot(props.selectedLot.lotId);
      updateBoard();
      props.lotUpdated();
      props.showNotification(`Lot gelöscht`, false);
      props.getAllLots();
    }
    catch(err) {
      console.log("Error:", {err});
      props.showNotification(`Lot konnte nicht gelöscht werden: ${err.message}`, true);
    }
  }

  const updateBoard = () => {
    let updateData = {
      action: apiConstants.UPDATE_BOARD_ACTION
    };
    sendMessage(updateData);
  }

  const finishDialogState = {
    open: true,
    acceptFunction: finishLot,
    title: 'Lot abschließen',
    message: `Wollen Sie das Lot mit der Freistellnummer ${props.selectedLot.lotId} wirklich abschließen?`
  }

  const activateDialogState = {
    open: true,
    acceptFunction: activateLot,
    title: 'Lot aktivieren',
    message: `Wollen Sie das Lot mit der Freistellnummer ${props.selectedLot.lotId} wirklich aktivieren?`
  }

  const deleteDialogState = {
    open: true,
    acceptFunction: deleteLot,
    title: 'Lot löschen',
    message: `Wollen Sie das Lot mit der Freistellnummer ${props.selectedLot.lotId} wirklich löschen? Dabei werden alle assoziierten Container auch gelöscht.`
  }

  const renderLotActionButtons = () => {
    if(props.selectedLot.lotState === 'active') {

      let disableFinishButton = true;

      if(parseInt(containers.items.length) === parseInt(props.selectedLot.containers)) {
        disableFinishButton = false;

        containers.items.forEach(container => {
          if(container.containerState !== apiConstants.STATE_TERMINAL) {
            disableFinishButton = true;
          }
        });
        
      }

      return (
        <Button 
          color='secondary' 
          variant='contained' 
          className={classes.pwButton}
          disabled={disableFinishButton}
          onClick={() => setConfirmDialog(finishDialogState)}
        >
          Lot<br/>abschließen
        </Button>
      );
    }
    else {
      return (
        <Button 
          color='secondary' 
          variant='contained' 
          className={classes.pwButton}
          onClick={() => setConfirmDialog(activateDialogState)}
        >
          Lot<br/>aktivieren
        </Button>
      );
    }
  }
  
  const renderLoader = () => {
    return(
      <CircularProgress />
    );
  }

  

  const renderItems = (container, index) => {
    let containerLabel =  `${container.containerNumber}. ${container.containerId} - ${translators.getContainerStateLabel(container.fixedState)}`;
    let currentContainerPosition = `Aktuelle Position: ${translators.getContainerStateLabel(container.containerState)}`;
    return (
      <ListItem key={index}>
        <ListItemText primary={containerLabel} secondary={currentContainerPosition} />
      </ListItem>
    );
  }

  const renderContainerList = () => {
    
    if(containers.fetching) {
      return renderLoader();
    } 
    else if(containers.error) {
      return (
        <div>
          { containers.error }
        </div>
      );
    }
    else {
      return (
        <List>
          {containers.items.map(renderItems)}
        </List>
      );
    }
    
  }

  

  const renderContainer = () => {
    if(props.selectedLot.lotId) {

      return (
        <div>
          <Grid container>

            <Grid item xs={12} md={6}>
              <Typography variant='h6' color='primary'>
                Lotnummer: {props.selectedLot.lotNumber}
              </Typography>
              <Typography variant='h6' color='primary'>
                Freistellnummer: {props.selectedLot.lotId}
              </Typography>
              <Typography variant='h6' color='textPrimary'>
                Containeranzahl: {props.selectedLot.containers}
              </Typography>
              <Typography variant='h6' color='textPrimary'>
                Status: {translators.getLotStateLabel(props.selectedLot.lotState)}
              </Typography>
              {renderLotActionButtons()}
              <br/>
              <Button 
                color='primary' 
                variant='contained' 
                className={classes.pwButton}
                onClick={() => setEditDialog(true)}
              >
                Lot<br/>bearbeiten
              </Button>
              <br/>
              <Button 
                color='inherit' 
                variant='contained' 
                className={classes.errorButton}
                onClick={() => setConfirmDialog(deleteDialogState)}
              >
                Lot<br/>löschen
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              {renderContainerList()}
            </Grid>
            
          </Grid>
        </div>
      );
    }
    else {
      return (
        <div>
          <Typography variant='h6'>
            Bitte wählen Sie ein Lot aus der Liste
          </Typography>
        </div>
      );
    }
  }

  return (
    <div className={classes.root}>
      <Paper elevation={4} className={classes.paper}>
        {false ? renderLoader() : renderContainer()}
        <AlertDialog 
          open={confirmDialog.open} 
          accept={confirmDialog.acceptFunction} 
          close={() => unsetConfirmDialog()}
          title={confirmDialog.title}
          text={confirmDialog.message}
        />
      </Paper>
      <EditLotDialog 
        lot={props.selectedLot} 
        update={() => {
          updateBoard();
          props.lotUpdated()
        }}
        open={editDialog} 
        close={() => setEditDialog(false)}
      />
    </div>
  );
}


export default connect(null, actions)(LotManagementDetails);
