import React, {useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Pages from '../pages/index';
import * as actions from '../actions/index';
import HeaderBar from '../components/layout/HeaderBar';
import NotificationBar from './layout/NotificationBar';
import { CssBaseline } from '@material-ui/core';


const App = (props) => {

  // check if user is already logged in
  useEffect( () => {
    props.checkIfUserIsAuthenticated()
  }, []);
  

  return (
    <div>
      <CssBaseline />
      <BrowserRouter>
        <div>
          <HeaderBar />
          <NotificationBar />
          <Switch>
            <Route path='/' exact component={Pages.AdminPage} />
            <Route path='/board' exact component={Pages.BoardPage} />
            <Route path='/login' exact component={Pages.LoginPage} />
            <Route path='/lotManagement' exact component={Pages.LotManagementPage} />
            <Route path='/userManagement' exact component={Pages.UserManagementPage} />
            <Route path='/export' exact component={Pages.ExportPage} />
          </Switch>
        </div>
       </BrowserRouter>
    </div>
  );
}

export default connect(null, actions)(App);