import React from 'react'
import { SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AdminIcon from '@material-ui/icons/Security';
import BoardIcon from '@material-ui/icons/TableChart';
import LotIcon from '@material-ui/icons/Build';
import UserIcon from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import {Link} from 'react-router-dom';

const useStyles = makeStyles( theme => ({
  list: {  
    width: 250,
  },
  drawer: {
    width: '300px'
  },
  link: {
    textDecoration: 'none'
  }
}))


const SideMenu = (props) => {

  const renderAdminPanelLink = () => {
    if(props.authRole === 'admin') {
      return (
        <React.Fragment>
          <List>
            <Link className={classes.link} to='/'>
              <ListItem button onClick={props.close}>
                <ListItemIcon>
                  <AdminIcon />
                </ListItemIcon>
                <ListItemText primary={'Admin Panel'} />
              </ListItem>
            </Link>
          </List>
          <Divider />
        </React.Fragment>
      )
    }
  }  

  const classes = useStyles();

  const renderManagementPages = () => {
    if(props.authRole === 'admin') {
      return (
        <React.Fragment>
          <Link className={classes.link} to='/lotManagement'>
            <ListItem button onClick={props.close}>
              <ListItemIcon>
                <LotIcon />
              </ListItemIcon>
              <ListItemText primary={'Lot Management'} />
            </ListItem>
          </Link>
              
          <Link className={classes.link} to='/userManagement'>
            <ListItem button onClick={props.close}>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary={'User Management'} />
            </ListItem>
          </Link>
        </React.Fragment>
      );
    }
  }

  const sideList = () => {
    return (
      <div className={classes.list}>
       
       {renderAdminPanelLink()}

        <List>

          <Link className={classes.link} to='/board'>
            <ListItem button onClick={props.close}>
              <ListItemIcon>
                <BoardIcon />
              </ListItemIcon>
              <ListItemText primary={'Board'} />
            </ListItem>
          </Link>

          
          <Link className={classes.link} to='/export'>
            <ListItem button onClick={props.close}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary={'Suche / Export'} />
            </ListItem>
          </Link>

          {renderManagementPages()}

        </List>
      </div>
    );
  }


  return (
    <SwipeableDrawer
      open={props.open}
      onClose={props.close}
      onOpen={props.openDrawer}
      className={classes.drawer}
      //onOpen={toggleDrawer('left', true)}
    >
      <div
        tabIndex={0}
        role="button"
        //onClick={toggleDrawer('left', false)}
        //onKeyDown={toggleDrawer('left', false)}
      >
        {sideList()}
      </div>
    </SwipeableDrawer>
  );
}

export default SideMenu;