import axios from 'axios';

import { API_URL } from '../constants/api/constants';
import * as authService from './AuthService';



export const requestUploadUrl = async() => {

  let url = `${API_URL}/file`;
  let body = {};

  try {
    let headers = await authService.getAuthHeaders();
    return axios.post(url,body, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
}


export const reqestDownloadUrl = async() => {

  let url = `${API_URL}/file`;

  try {
    let headers = await authService.getAuthHeaders();
    return axios.get(url, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
}


export const uploadFile = async(url, file) => {
  
  try {
    //let authHeaders = await authService.getAuthHeaders();
    let headers = {
      //'Content-Type' : 'multipart/form-data'
      'Content-Type' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    
    return axios.put(url,file, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
}


