import axios from 'axios';
import { API_URL } from '../constants/api/constants';
import * as authService from './AuthService';


export const getAllUsers = async() => {
  let url = `${API_URL}/user`;
  try {
    let headers = await authService.getAuthHeaders();
    return axios.get(url, {headers: headers});
  }
  catch(err){
    authService.logout();
  }
}

export const getSingleUser = async(userName) => {
  let url = `${API_URL}/user/${userName}`;
  try {
    let headers = await authService.getAuthHeaders();
    return axios.get(url, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
}

export const deleteSingleUser = async(userName) => {
  let url = `${API_URL}/user/${userName}`;

  try {
    let headers = await authService.getAuthHeaders();
    return axios.delete(url, {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
  
}

export const resetPwForSingleUser = async(userName, userAttributes) => {
  let url = `${API_URL}/user/${userName}`;

  console.log(userAttributes);
  let { 
    name,
    family_name,
    email,
    workerId 
  } = userAttributes;

  let body = {
    userName,
    name,
    familyName: family_name,
    email,
    workerId
  }

  console.log('body:', body);

  try {
    let headers = await authService.getAuthHeaders();
    return axios.post(url, JSON.stringify(body), {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
  
}

export const addNewUser = async(userName, userAttributes) => {
  let url = `${API_URL}/user`;
  let body = { 
    userName: userName,
    name: userAttributes.name || '',
    familyName: userAttributes.familyName || '',
    email: userAttributes.email || '',
    workerId: userAttributes.workerId || ''
  };

  try {
    let headers = await authService.getAuthHeaders();
    return axios.post(url, JSON.stringify(body), {headers: headers});
  }
  catch(err) {
    authService.logout();
  }
}
